import React, { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Table } from "antd";
import { DeleteTokonomicsHook, GetTokonmicsHook } from "hooks/adminHooks";
import AddTokonmicsModal from "components/common/modal/tokonmics/addTokonmics";
import UpdateTokonomicsModal from "components/common/modal/tokonmics/updateTokonomics";

const Admin = () => {
  const { data, getTokonmics } = GetTokonmicsHook();

  const {
    deleteTokonomics,
    loading: deleteLoading,
    data: deleteData,
  } = DeleteTokonomicsHook();
  const [modalVisible, setModalVisible] = React.useState(false);
  const [isDataAdded, setIsDataAdded] = React.useState(false);
  const [updateModalVisible, setupdateModalVisible] = React.useState(false);

  const [selectedValues, setSelectedValues] = React.useState({
    token_name: "",
    token_supply: "",
    id: "",
  });

  const [isDataUpdated, setIsDataUpdated] = React.useState(false);

  useEffect(() => {
    if (isDataAdded) {
      getTokonmics();
      setIsDataAdded(false);
    }
  }, [isDataAdded]);

  useEffect(() => {
    if (isDataUpdated) {
      getTokonmics();
      setIsDataUpdated(false);
    }
  }, [isDataUpdated]);

  useEffect(() => {
    if (deleteData && !deleteLoading) {
      getTokonmics();
    }
  }, [deleteData, deleteLoading]);

  const handleDelete = (id) => {
    deleteTokonomics(id);
    console.log(id);
  };

  const handleUpdate = (data) => {
    console.log("recordds", data);

    setSelectedValues({
      ...selectedValues,
      token_name: data?.token_name,
      token_supply: data?.token_supply,
      id: data?._id,
    });

    setupdateModalVisible(true);
  };

  const columns = [
    {
      title: "token_name",
      dataIndex: "token_name",
      key: "token_name",
    },
    {
      title: "token_supply",
      dataIndex: "token_supply",
      key: "token_supply",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (data, record) => {
        console.log("daaa", data, record);
        return (
          <div className="d-flex gap-2">
           
         
            <button
              onClick={() => handleUpdate(record)}
              className="tableBtnAdd"
            >
              Update
            </button>
            <button
              className="tableBtnAdd bg-danger" 
              onClick={() => handleDelete(record._id)} // Pass the id to handleDelete
            >
              Delete
            </button>
          </div>
        );
      },
    },
  ];
  return (
    <div className="my-5">
      <AddTokonmicsModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        setIsDataAdded={setIsDataAdded}
      />
      <UpdateTokonomicsModal
        updateModalVisible={updateModalVisible}
        setupdateModalVisible={setupdateModalVisible}
        setIsDataUpdated={setIsDataUpdated}
        initialValuesForUpdate={{
          token_name: selectedValues?.token_name,
          token_supply:  (selectedValues?.token_supply),
          id: selectedValues?.id,
        }}
      />
      <Container>
        <Row>
          <div className="d-flex justify-content-end mb-4">
          <button
              className="tableBtnAdds"
              onClick={() => {
                setModalVisible(true);
              }}
            >
              Add Tokonomics
            </button>
          </div>
    
          <Col lg={12}>
            <Table dataSource={data && data} columns={columns} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Admin;
