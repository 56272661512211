// import { twitterimg } from "assets";
import styled from "styled-components";

export const MainSection = styled.section`
  /* overflow: hidden; */

  .container {
    margin-top: 6rem;
    padding-bottom: 6rem;

    @media (max-width: 990px) {
      margin-top: 0rem;
    }
  }
`;

export const Title = styled.h5`
  color: #fff;
  text-align: center;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.5px; /* 42.969% */
  padding-bottom: 3rem;
  text-transform: uppercase;
  @media (max-width: 990px) {
    font-size: 40px;
  }

  @media (max-width: 365px) {
    font-size: 35px;
  }
`;

export const SocialWrappper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6rem;

  img {
    cursor: pointer;
    border-radius: 100px;
  }

  .hovered {
    filter: drop-shadow(-4px 4px 40px rgba(75, 174, 50, 0.72));
  }

  .timg:hover {
    fill: red !important;
    background-color: red;
  }

  @media (max-width: 767px) {
    display: flex;
    /* flex-wrap: wrap; */
    gap: 2rem;
  }
`;

export const TwitterImg = styled.img`
  fill: red !important;

  &:hover {
    fill: green !important;
  }
`;
