import { ConnectBtn, NavbarWrapper } from "./element";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { MainContainer } from "components/common";
import { Image } from "react-bootstrap";
import { logo, whitepaper } from "assets";
import { Modal } from "antd";
import ConnectModal from "../modal/connectModel/connectModal";
import { useEffect, useState } from "react";
import { useAppSelector } from "store/store";
import { CommonUtility } from "utility";
import { CHEPAFACTORYABI } from "web3Functions/chepafactory";
import environment from "environment";
import { FaShareSquare } from "react-icons/fa";
function Topbar() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chepaPrice, setChepaPrice] = useState("");
  const { web3, account } = useAppSelector((state) => state.web3Connect);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const getChepaPrice = async () => {
    try {
      const contract = CommonUtility.contract(
        web3,
        CHEPAFACTORYABI,
        environment.CHEPA_FACTORY
      );
      const chepaPrice = await contract.methods.getChepaPriceInEth().call();
      setChepaPrice(
        CommonUtility.convertFromWei(
          chepaPrice,
          environment.CHEPA_DECIMALS,
          false
        )
      );
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    if (web3) {
      getChepaPrice();
    }
  }, [web3]);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Modal
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="connectModal"
        centered
      >
        <ConnectModal onCancel={handleCancel} setIsModalOpen={setIsModalOpen} />
      </Modal>
      <NavbarWrapper>
        <Navbar bg="light" expand="lg">
          <MainContainer>
            <Navbar.Brand href="#home">
              <Image src={logo} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Nav.Link
                  // href="#about"
                  onClick={() => scrollToSection("about")}
                >
                  about
                </Nav.Link>
                <Nav.Link
                  // href="#buy"
                  onClick={() => scrollToSection("buy")}
                >
                  How to Buy
                </Nav.Link>
                <Nav.Link
                  // href="#tokennomics"
                  onClick={() => scrollToSection("tokennomics")}
                >
                  Tokenomics
                </Nav.Link>
                {/* <a href={whitepaper} download="whitepaper" target="_blank">
                  <button>
                    Whitepaper&nbsp;&nbsp;
                    <FaShareSquare fontSize={15} />
                  </button>
                </a> */}
                <Nav.Link
                  onClick={() =>
                    window.open(whitepaper, "_blank", "noreferrer")
                  }
                >
                  Whitepaper&nbsp;&nbsp;
                  <FaShareSquare fontSize={15} />
                </Nav.Link>
                <Nav.Link>
                  {+chepaPrice > 0 ? CommonUtility.decFixed(chepaPrice, 18) : 0}{" "}
                  ETH
                </Nav.Link>
              </Nav>
              {account ? (
                <ConnectBtn>CONNECTED </ConnectBtn>
              ) : (
                <ConnectBtn onClick={showModal}>CONNECT WALLET </ConnectBtn>
              )}
            </Navbar.Collapse>
          </MainContainer>
        </Navbar>
      </NavbarWrapper>
    </>
  );
}

export default Topbar;
