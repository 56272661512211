import React, { useState } from "react";
import { MainSection, SocialWrappper, Title, TwitterImg } from "./elements";
import { MainContainer } from "components/common";
import { Image } from "react-bootstrap";
import {
  facebookimg,
  hinsta,
  htelegram,
  htwitter,
  insta,
  telegramimg,
  twitterimg,
  youtubeimg,
} from "assets";

const JoinComp = () => {
  const handleTelegramClick = () => {
    window.open("https://t.me/+FJ6OMNLK0lFmZWRh", "_blank");
  };

  const handleTwitterClick = () => {
    window.open(
      "https://twitter.com/lachepa777?s=11&t=14QA_hh-MNjOVV8Hx26VIg",
      "_blank"
    );
  };

  const handleInstaClick = () => {
    window.open(
      "https://www.instagram.com/lachepa7/?igshid=MmIzYWVlNDQ5Yg%3D%3D",
      "_blank"
    );
  };

  const [hoveredImg, setHoveredImg] = useState(null);

  // Event handlers for image hover
  const handleMouseEnter = (image) => {
    setHoveredImg(image);
  };

  const handleMouseLeave = () => {
    setHoveredImg(null);
  };

  return (
    <MainSection>
      <MainContainer>
        <Title>JOIN our Community!</Title>

        <SocialWrappper onMouseLeave={handleMouseLeave}>
          <Image
            src={hoveredImg === "twitter" ? htwitter : twitterimg}
            fluid
            onClick={handleTwitterClick}
            onMouseEnter={() => handleMouseEnter("twitter")}
            className={hoveredImg === "twitter" && "hovered"}
            width={87}
            height={87}
          />

          <Image
            src={hoveredImg === "telegram" ? htelegram : telegramimg}
            fluid
            onClick={handleTelegramClick}
            onMouseEnter={() => handleMouseEnter("telegram")}
            className={hoveredImg === "telegram" && "hovered"}
            width={87}
            height={87}
          />
          <Image
            src={hoveredImg === "insta" ? hinsta : insta}
            fluid
            onClick={handleInstaClick}
            onMouseEnter={() => handleMouseEnter("insta")}
            className={hoveredImg === "insta" && "hovered"}
            width={87}
            height={87}
          />
        </SocialWrappper>
      </MainContainer>
    </MainSection>
  );
};

export default JoinComp;
