import {
  ConnectModalWrapper,
  ConnectWalletText,
  ButtonHolder,
  LeftDiv,
  BtnText,
  RightDiv,
} from "./connectModalElement";
import { MainCol, MainRow } from "components/common/mainStyle/mainStyle";
import {
  loadBlockchain,
  loadWalletConnect,
} from "store/redux/slices/wallet3Connect/web3ConnectSlice";
import { useAppDispatch } from "store/store";
import metmask from "../../../../assets/web3/metamask.png";
import wallet from "../../../../assets/web3/wallet.png";
import { AiOutlineClose } from "react-icons/ai";
import { useAppSelector } from "store/store";
import { useEffect } from "react";

const PaymentModal = ({ onCancel  }) => {
  const { account } = useAppSelector((state) => state.web3Connect);

  

  const dispatch = useAppDispatch();

  const handleWeb3MetaMask = async () => {
     
  };

  const handleWeb3WalletConnect = async () => {
    
  };

  return (
    <ConnectModalWrapper>
      <AiOutlineClose className="closeIcon" onClick={() => onCancel()} />
      <ConnectWalletText>Connect Wallet</ConnectWalletText>
      <ButtonHolder>
        <MainRow>
          <MainCol sm={6} className="d-flex justify-content-center">
            <LeftDiv  >
              <img className="img-fluid icon" src={metmask} />
              <BtnText>Connect Metamask</BtnText>
            </LeftDiv>
          </MainCol>
          <MainCol sm={6} className="d-flex justify-content-center">
            <RightDiv  >
              <img className="img-fluid icon" src={wallet} />
              <BtnText>Wallet Connect</BtnText>
            </RightDiv>
          </MainCol>
        </MainRow>
      </ButtonHolder>
    </ConnectModalWrapper>
  );
};

export default PaymentModal;
