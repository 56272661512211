export const networkObj = [
    {
        name: "Ethereum",
        value: 'ethereum',
    },
    {
        name: "Avalanche",
        value: 'avax',
    },
    {
        name: "Binance Smart Chain",
        value: 'bsc',
    },
    {
        name: "Polygon",
        value: 'polygon',
    },
    
]


export const marketplaceData = [
    {
        "id": 1,
        "name": "Bitcoin",
        "symbol": "BTC",
        "slug": "bitcoin",
        "num_market_pairs": 10386,
        "date_added": "2010-07-13T00:00:00.000Z",
        "tags": [
            "mineable",
            "pow",
            "sha-256",
            "store-of-value",
            "state-channel",
            "coinbase-ventures-portfolio",
            "three-arrows-capital-portfolio",
            "polychain-capital-portfolio",
            "binance-labs-portfolio",
            "blockchain-capital-portfolio",
            "boostvc-portfolio",
            "cms-holdings-portfolio",
            "dcg-portfolio",
            "dragonfly-capital-portfolio",
            "electric-capital-portfolio",
            "fabric-ventures-portfolio",
            "framework-ventures-portfolio",
            "galaxy-digital-portfolio",
            "huobi-capital-portfolio",
            "alameda-research-portfolio",
            "a16z-portfolio",
            "1confirmation-portfolio",
            "winklevoss-capital-portfolio",
            "usv-portfolio",
            "placeholder-ventures-portfolio",
            "pantera-capital-portfolio",
            "multicoin-capital-portfolio",
            "paradigm-portfolio",
            "bitcoin-ecosystem"
        ],
        "max_supply": 21000000,
        "circulating_supply": 19452950,
        "total_supply": 19452950,
        "infinite_supply": false,
        "platform": null,
        "cmc_rank": 1,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 29527.910076730903,
                "volume_24h": 17747838918.75663,
                "volume_change_24h": -2.5621,
                "percent_change_1h": -0.08289619,
                "percent_change_24h": -0.73312711,
                "percent_change_7d": 1.50916036,
                "percent_change_30d": -3.10841253,
                "percent_change_60d": 14.87441864,
                "percent_change_90d": 10.76597579,
                "market_cap": 574404958327.1425,
                "market_cap_dominance": 48.8066,
                "fully_diluted_market_cap": 620086111611.35,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 1027,
        "name": "Ethereum",
        "symbol": "ETH",
        "slug": "ethereum",
        "num_market_pairs": 7225,
        "date_added": "2015-08-07T00:00:00.000Z",
        "tags": [
            "pos",
            "smart-contracts",
            "ethereum-ecosystem",
            "coinbase-ventures-portfolio",
            "three-arrows-capital-portfolio",
            "polychain-capital-portfolio",
            "binance-labs-portfolio",
            "blockchain-capital-portfolio",
            "boostvc-portfolio",
            "cms-holdings-portfolio",
            "dcg-portfolio",
            "dragonfly-capital-portfolio",
            "electric-capital-portfolio",
            "fabric-ventures-portfolio",
            "framework-ventures-portfolio",
            "hashkey-capital-portfolio",
            "kenetic-capital-portfolio",
            "huobi-capital-portfolio",
            "alameda-research-portfolio",
            "a16z-portfolio",
            "1confirmation-portfolio",
            "winklevoss-capital-portfolio",
            "usv-portfolio",
            "placeholder-ventures-portfolio",
            "pantera-capital-portfolio",
            "multicoin-capital-portfolio",
            "paradigm-portfolio",
            "injective-ecosystem",
            "layer-1"
        ],
        "max_supply": null,
        "circulating_supply": 120152466.32010171,
        "total_supply": 120152466.32010171,
        "infinite_supply": true,
        "platform": null,
        "cmc_rank": 2,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 1850.6915287877132,
                "volume_24h": 5597464705.751273,
                "volume_change_24h": -4.4449,
                "percent_change_1h": -0.06089954,
                "percent_change_24h": -0.2739755,
                "percent_change_7d": 0.87024098,
                "percent_change_30d": -1.51835974,
                "percent_change_60d": 5.87933797,
                "percent_change_90d": 4.87826763,
                "market_cap": 222365151581.56326,
                "market_cap_dominance": 18.8941,
                "fully_diluted_market_cap": 222365151581.56,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 825,
        "name": "Tether USDt",
        "symbol": "USDT",
        "slug": "tether",
        "num_market_pairs": 59204,
        "date_added": "2015-02-25T00:00:00.000Z",
        "tags": [
            "payments",
            "stablecoin",
            "asset-backed-stablecoin",
            "avalanche-ecosystem",
            "solana-ecosystem",
            "arbitrum-ecosytem",
            "moonriver-ecosystem",
            "injective-ecosystem",
            "bnb-chain",
            "usd-stablecoin",
            "optimism-ecosystem"
        ],
        "max_supply": null,
        "circulating_supply": 83532928351.15138,
        "total_supply": 86684257831.22757,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
        },
        "infinite_supply": true,
        "cmc_rank": 3,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.9990653300358404,
                "volume_24h": 22244312098.78612,
                "volume_change_24h": -15.7256,
                "percent_change_1h": -0.01516435,
                "percent_change_24h": -0.05849966,
                "percent_change_7d": -0.02920083,
                "percent_change_30d": -0.0930781,
                "percent_change_60d": -0.14203202,
                "percent_change_90d": -0.13396139,
                "market_cap": 83454852632.00327,
                "market_cap_dominance": 7.0911,
                "fully_diluted_market_cap": 86603236659.07,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 1839,
        "name": "BNB",
        "symbol": "BNB",
        "slug": "bnb",
        "num_market_pairs": 1560,
        "date_added": "2017-07-25T00:00:00.000Z",
        "tags": [
            "marketplace",
            "centralized-exchange",
            "payments",
            "smart-contracts",
            "alameda-research-portfolio",
            "multicoin-capital-portfolio",
            "bnb-chain",
            "layer-1",
            "sec-security-token",
            "alleged-sec-securities",
            "celsius-bankruptcy-estate"
        ],
        "max_supply": null,
        "circulating_supply": 153853449.82325837,
        "total_supply": 153853449.82325837,
        "infinite_supply": false,
        "platform": null,
        "cmc_rank": 4,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 243.09700079891417,
                "volume_24h": 378305845.6186898,
                "volume_change_24h": -26.995,
                "percent_change_1h": -0.02412302,
                "percent_change_24h": -0.52481114,
                "percent_change_7d": 1.4052863,
                "percent_change_30d": -1.35907763,
                "percent_change_60d": 3.22806622,
                "percent_change_90d": -20.38832165,
                "market_cap": 37401312214.60034,
                "market_cap_dominance": 3.1779,
                "fully_diluted_market_cap": 37401312214.6,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 52,
        "name": "XRP",
        "symbol": "XRP",
        "slug": "xrp",
        "num_market_pairs": 1026,
        "date_added": "2013-08-04T00:00:00.000Z",
        "tags": [
            "medium-of-exchange",
            "enterprise-solutions",
            "arrington-xrp-capital-portfolio",
            "galaxy-digital-portfolio",
            "a16z-portfolio",
            "pantera-capital-portfolio"
        ],
        "max_supply": 100000000000,
        "circulating_supply": 52741286353,
        "total_supply": 99988538151,
        "infinite_supply": false,
        "platform": null,
        "cmc_rank": 5,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.6374032359233018,
                "volume_24h": 1678053669.5452554,
                "volume_change_24h": 29.976,
                "percent_change_1h": -0.05481327,
                "percent_change_24h": -0.74908644,
                "percent_change_7d": -6.26866066,
                "percent_change_30d": 33.89090436,
                "percent_change_60d": 26.37884231,
                "percent_change_90d": 51.91098314,
                "market_cap": 33617466588.15968,
                "market_cap_dominance": 2.8565,
                "fully_diluted_market_cap": 63740323592.33,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 3408,
        "name": "USD Coin",
        "symbol": "USDC",
        "slug": "usd-coin",
        "num_market_pairs": 13556,
        "date_added": "2018-10-08T00:00:00.000Z",
        "tags": [
            "medium-of-exchange",
            "stablecoin",
            "asset-backed-stablecoin",
            "hedera-hashgraph-ecosystem",
            "fantom-ecosystem",
            "arbitrum-ecosytem",
            "moonriver-ecosystem",
            "bnb-chain",
            "usd-stablecoin",
            "optimism-ecosystem"
        ],
        "max_supply": null,
        "circulating_supply": 26286696649.677258,
        "total_supply": 26286696649.677258,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48"
        },
        "infinite_supply": false,
        "cmc_rank": 6,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.9999680185943423,
                "volume_24h": 3209269028.8553185,
                "volume_change_24h": -7.5522,
                "percent_change_1h": -0.00263756,
                "percent_change_24h": -0.01084112,
                "percent_change_7d": -0.00159298,
                "percent_change_30d": -0.0041099,
                "percent_change_60d": -0.00053516,
                "percent_change_90d": 0.00875027,
                "market_cap": 26285855964.1683,
                "market_cap_dominance": 2.2335,
                "fully_diluted_market_cap": 26285855964.17,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 74,
        "name": "Dogecoin",
        "symbol": "DOGE",
        "slug": "dogecoin",
        "num_market_pairs": 765,
        "date_added": "2013-12-15T00:00:00.000Z",
        "tags": [
            "mineable",
            "pow",
            "scrypt",
            "medium-of-exchange",
            "memes",
            "payments",
            "doggone-doggerel",
            "bnb-chain"
        ],
        "max_supply": null,
        "circulating_supply": 140527926383.70523,
        "total_supply": 140527926383.70523,
        "infinite_supply": true,
        "platform": null,
        "cmc_rank": 7,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.07572053891475011,
                "volume_24h": 371498434.447456,
                "volume_change_24h": 22.7164,
                "percent_change_1h": -0.1444953,
                "percent_change_24h": 1.09913022,
                "percent_change_7d": 1.81994992,
                "percent_change_30d": 16.03342733,
                "percent_change_60d": 22.05631437,
                "percent_change_90d": 6.38098965,
                "market_cap": 10640850318.34649,
                "market_cap_dominance": 0.9041,
                "fully_diluted_market_cap": 10640850318.35,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 2010,
        "name": "Cardano",
        "symbol": "ADA",
        "slug": "cardano",
        "num_market_pairs": 889,
        "date_added": "2017-10-01T00:00:00.000Z",
        "tags": [
            "dpos",
            "pos",
            "platform",
            "research",
            "smart-contracts",
            "staking",
            "cardano-ecosystem",
            "cardano",
            "layer-1",
            "sec-security-token",
            "alleged-sec-securities"
        ],
        "max_supply": 45000000000,
        "circulating_supply": 35027651195.501,
        "total_supply": 36061170836.355,
        "infinite_supply": false,
        "platform": null,
        "cmc_rank": 8,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.29985589715498545,
                "volume_24h": 235413641.12463155,
                "volume_change_24h": -26.3612,
                "percent_change_1h": -0.27325368,
                "percent_change_24h": 0.69546804,
                "percent_change_7d": 0.37211061,
                "percent_change_30d": 3.36469387,
                "percent_change_60d": 14.37234141,
                "percent_change_90d": -16.47170781,
                "market_cap": 10503247774.45885,
                "market_cap_dominance": 0.8924,
                "fully_diluted_market_cap": 13493515371.97,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 5426,
        "name": "Solana",
        "symbol": "SOL",
        "slug": "solana",
        "num_market_pairs": 496,
        "date_added": "2020-04-10T00:00:00.000Z",
        "tags": [
            "pos",
            "platform",
            "solana-ecosystem",
            "cms-holdings-portfolio",
            "kenetic-capital-portfolio",
            "alameda-research-portfolio",
            "multicoin-capital-portfolio",
            "okex-blockdream-ventures-portfolio",
            "layer-1",
            "ftx-bankruptcy-estate",
            "sec-security-token",
            "alleged-sec-securities"
        ],
        "max_supply": null,
        "circulating_supply": 405783533.78025407,
        "total_supply": 554579203.83172,
        "infinite_supply": true,
        "platform": null,
        "cmc_rank": 9,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 24.263530404171238,
                "volume_24h": 437864553.75089204,
                "volume_change_24h": -14.5365,
                "percent_change_1h": 0.15230909,
                "percent_change_24h": -0.66506668,
                "percent_change_7d": 4.86033801,
                "percent_change_30d": 10.90439996,
                "percent_change_60d": 57.6105961,
                "percent_change_90d": 20.79207551,
                "market_cap": 9845741109.38924,
                "market_cap_dominance": 0.8366,
                "fully_diluted_market_cap": 13456049373.69,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 1958,
        "name": "TRON",
        "symbol": "TRX",
        "slug": "tron",
        "num_market_pairs": 808,
        "date_added": "2017-09-13T00:00:00.000Z",
        "tags": [
            "media",
            "payments",
            "tron-ecosystem",
            "sec-security-token",
            "alleged-sec-securities"
        ],
        "max_supply": null,
        "circulating_supply": 89515616937.95998,
        "total_supply": 89515632376.65648,
        "infinite_supply": true,
        "platform": null,
        "cmc_rank": 10,
        "self_reported_circulating_supply": 71659659264,
        "self_reported_market_cap": 5513591541.193667,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.07694135860849056,
                "volume_24h": 193014668.5096907,
                "volume_change_24h": 6.5829,
                "percent_change_1h": 0.16360435,
                "percent_change_24h": 0.4760629,
                "percent_change_7d": 0.64757299,
                "percent_change_30d": -0.58015037,
                "percent_change_60d": 11.57351808,
                "percent_change_90d": 13.96840592,
                "market_cap": 6887453183.883851,
                "market_cap_dominance": 0.5852,
                "fully_diluted_market_cap": 6887454371.76,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 3890,
        "name": "Polygon",
        "symbol": "MATIC",
        "slug": "polygon",
        "num_market_pairs": 800,
        "date_added": "2019-04-28T00:00:00.000Z",
        "tags": [
            "pos",
            "platform",
            "enterprise-solutions",
            "zero-knowledge-proofs",
            "scaling",
            "state-channel",
            "coinbase-ventures-portfolio",
            "layer-2",
            "binance-launchpad",
            "binance-labs-portfolio",
            "polygon-ecosystem",
            "moonriver-ecosystem",
            "injective-ecosystem",
            "sec-security-token",
            "alleged-sec-securities"
        ],
        "max_supply": 10000000000,
        "circulating_supply": 9319469069.28493,
        "total_supply": 10000000000,
        "infinite_supply": false,
        "platform": null,
        "cmc_rank": 11,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.6880797365279142,
                "volume_24h": 263106805.0509507,
                "volume_change_24h": -19.5674,
                "percent_change_1h": -0.13419257,
                "percent_change_24h": -0.16856108,
                "percent_change_7d": 1.22036118,
                "percent_change_30d": -7.0504866,
                "percent_change_60d": 15.59515251,
                "percent_change_90d": -17.12510007,
                "market_cap": 6412537821.77362,
                "market_cap_dominance": 0.5449,
                "fully_diluted_market_cap": 6880797365.28,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 2,
        "name": "Litecoin",
        "symbol": "LTC",
        "slug": "litecoin",
        "num_market_pairs": 968,
        "date_added": "2013-04-28T00:00:00.000Z",
        "tags": [
            "mineable",
            "pow",
            "scrypt",
            "medium-of-exchange"
        ],
        "max_supply": 84000000,
        "circulating_supply": 73524607.97743528,
        "total_supply": 84000000,
        "infinite_supply": false,
        "platform": null,
        "cmc_rank": 12,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 84.09037377723385,
                "volume_24h": 1074662971.28256,
                "volume_change_24h": 144.679,
                "percent_change_1h": 0.00537505,
                "percent_change_24h": 0.19032224,
                "percent_change_7d": -3.23320658,
                "percent_change_30d": -13.24455782,
                "percent_change_60d": 9.8302248,
                "percent_change_90d": 7.04246018,
                "market_cap": 6182711766.647122,
                "market_cap_dominance": 0.5253,
                "fully_diluted_market_cap": 7063591397.29,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 6636,
        "name": "Polkadot",
        "symbol": "DOT",
        "slug": "polkadot-new",
        "num_market_pairs": 553,
        "date_added": "2020-08-19T00:00:00.000Z",
        "tags": [
            "substrate",
            "polkadot",
            "binance-chain",
            "polkadot-ecosystem",
            "three-arrows-capital-portfolio",
            "polychain-capital-portfolio",
            "arrington-xrp-capital-portfolio",
            "blockchain-capital-portfolio",
            "boostvc-portfolio",
            "cms-holdings-portfolio",
            "coinfund-portfolio",
            "fabric-ventures-portfolio",
            "fenbushi-capital-portfolio",
            "hashkey-capital-portfolio",
            "kenetic-capital-portfolio",
            "1confirmation-portfolio",
            "placeholder-ventures-portfolio",
            "pantera-capital-portfolio",
            "exnetwork-capital-portfolio",
            "web3",
            "spartan-group",
            "injective-ecosystem",
            "bnb-chain",
            "layer-1"
        ],
        "max_supply": null,
        "circulating_supply": 1213991189.0287516,
        "total_supply": 1343470066.3339314,
        "infinite_supply": true,
        "platform": null,
        "cmc_rank": 13,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 5.037798464806055,
                "volume_24h": 193994664.60805935,
                "volume_change_24h": 111.5089,
                "percent_change_1h": -0.13968264,
                "percent_change_24h": -0.1762632,
                "percent_change_7d": 0.23448288,
                "percent_change_30d": -1.77518915,
                "percent_change_60d": 12.88575709,
                "percent_change_90d": -3.38555984,
                "market_cap": 6115842948.377123,
                "market_cap_dominance": 0.5197,
                "fully_diluted_market_cap": 6768131437.69,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 5994,
        "name": "Shiba Inu",
        "symbol": "SHIB",
        "slug": "shiba-inu",
        "num_market_pairs": 595,
        "date_added": "2020-08-01T00:00:00.000Z",
        "tags": [
            "memes",
            "ethereum-ecosystem",
            "doggone-doggerel"
        ],
        "max_supply": null,
        "circulating_supply": 589346914631298.1,
        "total_supply": 589589040239379.6,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce"
        },
        "infinite_supply": false,
        "cmc_rank": 14,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.000009792920939409392,
                "volume_24h": 363862900.0193296,
                "volume_change_24h": 5.3997,
                "percent_change_1h": 0.59830883,
                "percent_change_24h": 3.03651809,
                "percent_change_7d": 19.42346843,
                "percent_change_30d": 29.14358377,
                "percent_change_60d": 44.53818452,
                "percent_change_90d": 14.09820391,
                "market_cap": 5771427740.869159,
                "market_cap_dominance": 0.4904,
                "fully_diluted_market_cap": 5773798857.81,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 4943,
        "name": "Dai",
        "symbol": "DAI",
        "slug": "multi-collateral-dai",
        "num_market_pairs": 2490,
        "date_added": "2019-11-22T00:00:00.000Z",
        "tags": [
            "defi",
            "stablecoin",
            "asset-backed-stablecoin",
            "ethereum-ecosystem",
            "avalanche-ecosystem",
            "polygon-ecosystem",
            "fantom-ecosystem",
            "arbitrum-ecosytem",
            "harmony-ecosystem",
            "moonriver-ecosystem",
            "bnb-chain",
            "usd-stablecoin",
            "optimism-ecosystem"
        ],
        "max_supply": null,
        "circulating_supply": 5155479973.095717,
        "total_supply": 5155479973.095717,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x6b175474e89094c44da98b954eedeac495271d0f"
        },
        "infinite_supply": true,
        "cmc_rank": 15,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.9996934086369812,
                "volume_24h": 107815182.63243645,
                "volume_change_24h": -22.2869,
                "percent_change_1h": -0.00460626,
                "percent_change_24h": -0.00661254,
                "percent_change_7d": 0.06206404,
                "percent_change_30d": -0.00596412,
                "percent_change_60d": 0.05826016,
                "percent_change_90d": 0.00954226,
                "market_cap": 5153899347.46375,
                "market_cap_dominance": 0.4379,
                "fully_diluted_market_cap": 5153899347.46,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 3717,
        "name": "Wrapped Bitcoin",
        "symbol": "WBTC",
        "slug": "wrapped-bitcoin",
        "num_market_pairs": 1312,
        "date_added": "2019-01-30T00:00:00.000Z",
        "tags": [
            "medium-of-exchange",
            "defi",
            "wrapped-tokens",
            "fantom-ecosystem",
            "arbitrum-ecosytem",
            "moonriver-ecosystem",
            "optimism-ecosystem",
            "bitcoin-ecosystem"
        ],
        "max_supply": null,
        "circulating_supply": 162205.47768531,
        "total_supply": 162205.47768531,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599"
        },
        "infinite_supply": false,
        "cmc_rank": 16,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 29544.164505422275,
                "volume_24h": 160786894.26660568,
                "volume_change_24h": -17.1912,
                "percent_change_1h": -0.06306266,
                "percent_change_24h": -0.63310523,
                "percent_change_7d": 1.41202202,
                "percent_change_30d": -5.1038164,
                "percent_change_60d": 14.88090397,
                "percent_change_90d": 10.82739021,
                "market_cap": 4792225316.4154005,
                "market_cap_dominance": 0.4072,
                "fully_diluted_market_cap": 4792225316.42,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 1831,
        "name": "Bitcoin Cash",
        "symbol": "BCH",
        "slug": "bitcoin-cash",
        "num_market_pairs": 748,
        "date_added": "2017-07-23T00:00:00.000Z",
        "tags": [
            "mineable",
            "pow",
            "sha-256",
            "marketplace",
            "medium-of-exchange",
            "store-of-value",
            "enterprise-solutions",
            "payments",
            "bitcoin-ecosystem",
            "layer-1"
        ],
        "max_supply": 21000000,
        "circulating_supply": 19472168.75,
        "total_supply": 19472168.75,
        "infinite_supply": false,
        "platform": null,
        "cmc_rank": 17,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 237.53489001431333,
                "volume_24h": 558499402.5089914,
                "volume_change_24h": 26.7717,
                "percent_change_1h": -0.51351634,
                "percent_change_24h": 0.69376355,
                "percent_change_7d": 3.51720426,
                "percent_change_30d": -12.96534817,
                "percent_change_60d": 132.95176083,
                "percent_change_90d": 109.1923506,
                "market_cap": 4625319462.371399,
                "market_cap_dominance": 0.393,
                "fully_diluted_market_cap": 4988232690.3,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 11419,
        "name": "Toncoin",
        "symbol": "TON",
        "slug": "toncoin",
        "num_market_pairs": 125,
        "date_added": "2021-08-26T13:40:22.000Z",
        "tags": [
            "pos"
        ],
        "max_supply": null,
        "circulating_supply": 3431892087.62,
        "total_supply": 5047558528,
        "infinite_supply": true,
        "platform": null,
        "cmc_rank": 18,
        "self_reported_circulating_supply": 3414166606,
        "self_reported_market_cap": 4364073735.785033,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 1.2782251833040843,
                "volume_24h": 27398631.95332608,
                "volume_change_24h": -4.0187,
                "percent_change_1h": -0.17408544,
                "percent_change_24h": 0.19710815,
                "percent_change_7d": 7.1621896,
                "percent_change_30d": -6.81409629,
                "percent_change_60d": -11.95393648,
                "percent_change_90d": -33.00404385,
                "market_cap": 4386730892.777911,
                "market_cap_dominance": 0.3727,
                "fully_diluted_market_cap": 6451916424.69,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 5805,
        "name": "Avalanche",
        "symbol": "AVAX",
        "slug": "avalanche",
        "num_market_pairs": 481,
        "date_added": "2020-07-13T00:00:00.000Z",
        "tags": [
            "defi",
            "smart-contracts",
            "three-arrows-capital-portfolio",
            "polychain-capital-portfolio",
            "avalanche-ecosystem",
            "cms-holdings-portfolio",
            "dragonfly-capital-portfolio",
            "moonriver-ecosystem",
            "injective-ecosystem",
            "layer-1"
        ],
        "max_supply": 720000000,
        "circulating_supply": 343810972.67381847,
        "total_supply": 430530422.67381847,
        "infinite_supply": false,
        "platform": null,
        "cmc_rank": 19,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 12.550924757223775,
                "volume_24h": 107666455.18471867,
                "volume_change_24h": -15.4941,
                "percent_change_1h": -0.18370468,
                "percent_change_24h": -0.72147535,
                "percent_change_7d": 0.47173379,
                "percent_change_30d": -7.88960242,
                "percent_change_60d": 8.51745351,
                "percent_change_90d": -15.20239712,
                "market_cap": 4315145648.737015,
                "market_cap_dominance": 0.3667,
                "fully_diluted_market_cap": 9036665825.2,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 1975,
        "name": "Chainlink",
        "symbol": "LINK",
        "slug": "chainlink",
        "num_market_pairs": 1288,
        "date_added": "2017-09-20T00:00:00.000Z",
        "tags": [
            "platform",
            "defi",
            "oracles",
            "smart-contracts",
            "substrate",
            "polkadot",
            "polkadot-ecosystem",
            "avalanche-ecosystem",
            "solana-ecosystem",
            "framework-ventures-portfolio",
            "polygon-ecosystem",
            "fantom-ecosystem",
            "cardano-ecosystem",
            "web3",
            "near-protocol-ecosystem",
            "arbitrum-ecosytem",
            "cardano",
            "injective-ecosystem",
            "optimism-ecosystem",
            "celsius-bankruptcy-estate"
        ],
        "max_supply": 1000000000,
        "circulating_supply": 538099970.4527867,
        "total_supply": 1000000000,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x514910771af9ca656af840dff83e8264ecf986ca"
        },
        "infinite_supply": false,
        "cmc_rank": 20,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 7.624818426353225,
                "volume_24h": 643980959.858019,
                "volume_change_24h": 161.4353,
                "percent_change_1h": -0.25484015,
                "percent_change_24h": 2.81353125,
                "percent_change_7d": 3.88518313,
                "percent_change_30d": 23.47344958,
                "percent_change_60d": 47.56858436,
                "percent_change_90d": 21.20720584,
                "market_cap": 4102914569.928534,
                "market_cap_dominance": 0.3486,
                "fully_diluted_market_cap": 7624818426.35,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 512,
        "name": "Stellar",
        "symbol": "XLM",
        "slug": "stellar",
        "num_market_pairs": 513,
        "date_added": "2014-08-05T00:00:00.000Z",
        "tags": [
            "medium-of-exchange",
            "enterprise-solutions",
            "decentralized-exchange-dex-token",
            "smart-contracts",
            "hashkey-capital-portfolio"
        ],
        "max_supply": 50001806812,
        "circulating_supply": 27367746951.60136,
        "total_supply": 50001787251.47362,
        "infinite_supply": false,
        "platform": null,
        "cmc_rank": 21,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.13956662792057317,
                "volume_24h": 130496620.7575656,
                "volume_change_24h": -1.1927,
                "percent_change_1h": -0.23270266,
                "percent_change_24h": -1.74233461,
                "percent_change_7d": -2.28438106,
                "percent_change_30d": 39.81579738,
                "percent_change_60d": 71.03478564,
                "percent_change_90d": 57.26934404,
                "market_cap": 3819624155.8185477,
                "market_cap_dominance": 0.3245,
                "fully_diluted_market_cap": 6978583566.69,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 3957,
        "name": "UNUS SED LEO",
        "symbol": "LEO",
        "slug": "unus-sed-leo",
        "num_market_pairs": 27,
        "date_added": "2019-05-21T00:00:00.000Z",
        "tags": [
            "marketplace",
            "centralized-exchange",
            "discount-token",
            "payments",
            "kenetic-capital-portfolio",
            "alameda-research-portfolio"
        ],
        "max_supply": null,
        "circulating_supply": 929660861.9,
        "total_supply": 985239504,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x2af5d2ad76741191d15dfe7bf6ac92d4bd912ca3"
        },
        "infinite_supply": false,
        "cmc_rank": 22,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 4.010276170977476,
                "volume_24h": 1787158.30231721,
                "volume_change_24h": 2.5392,
                "percent_change_1h": -0.12023366,
                "percent_change_24h": -0.03445653,
                "percent_change_7d": 1.64279999,
                "percent_change_30d": 1.16733346,
                "percent_change_60d": 14.27713045,
                "percent_change_90d": 14.8473222,
                "market_cap": 3728196801.567952,
                "market_cap_dominance": 0.3168,
                "fully_diluted_market_cap": 3951082505.6,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 7083,
        "name": "Uniswap",
        "symbol": "UNI",
        "slug": "uniswap",
        "num_market_pairs": 772,
        "date_added": "2020-09-17T00:00:00.000Z",
        "tags": [
            "decentralized-exchange-dex-token",
            "defi",
            "dao",
            "yield-farming",
            "amm",
            "coinbase-ventures-portfolio",
            "three-arrows-capital-portfolio",
            "governance",
            "blockchain-capital-portfolio",
            "defiance-capital-portfolio",
            "alameda-research-portfolio",
            "a16z-portfolio",
            "pantera-capital-portfolio",
            "parafi-capital",
            "paradigm-portfolio",
            "arbitrum-ecosytem",
            "injective-ecosystem",
            "optimism-ecosystem"
        ],
        "max_supply": 1000000000,
        "circulating_supply": 577501036,
        "total_supply": 1000000000,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984"
        },
        "infinite_supply": false,
        "cmc_rank": 23,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": 2.99659055,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 6.28210798618011,
                "volume_24h": 103783407.7343817,
                "volume_change_24h": 11.3974,
                "percent_change_1h": -0.36732809,
                "percent_change_24h": 3.67655278,
                "percent_change_7d": 2.20060302,
                "percent_change_30d": 19.9852935,
                "percent_change_60d": 54.79680768,
                "percent_change_90d": 26.29178612,
                "market_cap": 3627923870.282887,
                "market_cap_dominance": 0.3083,
                "fully_diluted_market_cap": 6282107986.18,
                "tvl": 1210683880.4808002,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 4687,
        "name": "Binance USD",
        "symbol": "BUSD",
        "slug": "binance-usd",
        "num_market_pairs": 7087,
        "date_added": "2019-09-20T00:00:00.000Z",
        "tags": [
            "stablecoin",
            "asset-backed-stablecoin",
            "binance-chain",
            "harmony-ecosystem",
            "moonriver-ecosystem",
            "usd-stablecoin",
            "optimism-ecosystem",
            "alleged-sec-securities",
            "linea-ecosystem"
        ],
        "max_supply": null,
        "circulating_supply": 3391737648.549097,
        "total_supply": 3391737648.549097,
        "platform": {
            "id": 1839,
            "name": "BNB Beacon Chain (BEP2)",
            "symbol": "BNB",
            "slug": "bnb",
            "token_address": "BUSD-BD1"
        },
        "infinite_supply": true,
        "cmc_rank": 24,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.9999764862965924,
                "volume_24h": 1282126700.0102708,
                "volume_change_24h": -17.2127,
                "percent_change_1h": 0.00052182,
                "percent_change_24h": -0.07131055,
                "percent_change_7d": 0.01438955,
                "percent_change_30d": -0.00384761,
                "percent_change_60d": 0.02650476,
                "percent_change_90d": -0.015979,
                "market_cap": 3391657896.235993,
                "market_cap_dominance": 0.2882,
                "fully_diluted_market_cap": 3391657896.24,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 2563,
        "name": "TrueUSD",
        "symbol": "TUSD",
        "slug": "trueusd",
        "num_market_pairs": 412,
        "date_added": "2018-03-06T00:00:00.000Z",
        "tags": [
            "store-of-value",
            "stablecoin",
            "asset-backed-stablecoin",
            "avalanche-ecosystem",
            "arbitrum-ecosytem",
            "bnb-chain",
            "usd-stablecoin"
        ],
        "max_supply": null,
        "circulating_supply": 2993074781.45,
        "total_supply": 2993074781.45,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x0000000000085d4780B73119b644AE5ecd22b376"
        },
        "infinite_supply": false,
        "cmc_rank": 25,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.999692726954625,
                "volume_24h": 2508471224.1599274,
                "volume_change_24h": -15.2707,
                "percent_change_1h": 0.00587486,
                "percent_change_24h": -0.06858341,
                "percent_change_7d": -0.00402348,
                "percent_change_30d": 0.02011543,
                "percent_change_60d": 0.28037795,
                "percent_change_90d": 0.09675191,
                "market_cap": 2992155090.2468686,
                "market_cap_dominance": 0.2542,
                "fully_diluted_market_cap": 2992155090.25,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 3794,
        "name": "Cosmos",
        "symbol": "ATOM",
        "slug": "cosmos",
        "num_market_pairs": 478,
        "date_added": "2019-03-14T00:00:00.000Z",
        "tags": [
            "platform",
            "cosmos-ecosystem",
            "content-creation",
            "interoperability",
            "polychain-capital-portfolio",
            "dragonfly-capital-portfolio",
            "hashkey-capital-portfolio",
            "1confirmation-portfolio",
            "paradigm-portfolio",
            "exnetwork-capital-portfolio",
            "injective-ecosystem",
            "sec-security-token",
            "alleged-sec-securities"
        ],
        "max_supply": null,
        "circulating_supply": 346608690,
        "total_supply": 0,
        "infinite_supply": true,
        "platform": null,
        "cmc_rank": 26,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 8.459375256079367,
                "volume_24h": 74265929.97194509,
                "volume_change_24h": -6.6291,
                "percent_change_1h": -0.32755195,
                "percent_change_24h": -0.48523392,
                "percent_change_7d": -2.7624844,
                "percent_change_30d": -9.05281516,
                "percent_change_60d": 3.13412243,
                "percent_change_90d": -22.82064911,
                "market_cap": 2932092975.728084,
                "market_cap_dominance": 0.2491,
                "fully_diluted_market_cap": 0,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 328,
        "name": "Monero",
        "symbol": "XMR",
        "slug": "monero",
        "num_market_pairs": 227,
        "date_added": "2014-05-21T00:00:00.000Z",
        "tags": [
            "mineable",
            "pow",
            "medium-of-exchange",
            "privacy",
            "ringct",
            "boostvc-portfolio",
            "electric-capital-portfolio",
            "galaxy-digital-portfolio"
        ],
        "max_supply": null,
        "circulating_supply": 18316371.29647432,
        "total_supply": 18316371.29647432,
        "infinite_supply": true,
        "platform": null,
        "cmc_rank": 27,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 156.6113965773553,
                "volume_24h": 83865647.2813065,
                "volume_change_24h": -3.0931,
                "percent_change_1h": -0.00491952,
                "percent_change_24h": -1.14876339,
                "percent_change_7d": -2.84556621,
                "percent_change_30d": -4.66738804,
                "percent_change_60d": 14.72830112,
                "percent_change_90d": 2.87897412,
                "market_cap": 2868552488.970227,
                "market_cap_dominance": 0.2437,
                "fully_diluted_market_cap": 2868552488.97,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 3897,
        "name": "OKB",
        "symbol": "OKB",
        "slug": "okb",
        "num_market_pairs": 99,
        "date_added": "2019-04-30T00:00:00.000Z",
        "tags": [
            "marketplace",
            "centralized-exchange",
            "discount-token",
            "alameda-research-portfolio"
        ],
        "max_supply": null,
        "circulating_supply": 60000000,
        "total_supply": 300000000,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x75231f58b43240c9718dd58b4967c5114342a86c"
        },
        "infinite_supply": false,
        "cmc_rank": 28,
        "self_reported_circulating_supply": 246638974,
        "self_reported_market_cap": 11772420737.678535,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 47.73138870452216,
                "volume_24h": 7052593.06778862,
                "volume_change_24h": 2.7876,
                "percent_change_1h": -1.12885358,
                "percent_change_24h": 1.46222406,
                "percent_change_7d": 13.46066843,
                "percent_change_30d": 11.08434073,
                "percent_change_60d": 16.03685468,
                "percent_change_90d": 10.24879281,
                "market_cap": 2863883322.2713294,
                "market_cap_dominance": 0.2433,
                "fully_diluted_market_cap": 14319416611.36,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 1321,
        "name": "Ethereum Classic",
        "symbol": "ETC",
        "slug": "ethereum-classic",
        "num_market_pairs": 437,
        "date_added": "2016-07-24T00:00:00.000Z",
        "tags": [
            "mineable",
            "pow",
            "ethash",
            "platform",
            "smart-contracts",
            "dcg-portfolio"
        ],
        "max_supply": 210700000,
        "circulating_supply": 142410290.83203715,
        "total_supply": 210700000,
        "infinite_supply": false,
        "platform": null,
        "cmc_rank": 29,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 17.77401801448013,
                "volume_24h": 125399759.02231154,
                "volume_change_24h": 13.0964,
                "percent_change_1h": -0.01910704,
                "percent_change_24h": -0.81102379,
                "percent_change_7d": -1.37298609,
                "percent_change_30d": -5.9627742,
                "percent_change_60d": 19.35046866,
                "percent_change_90d": -1.01129769,
                "market_cap": 2531203074.695983,
                "market_cap_dominance": 0.2151,
                "fully_diluted_market_cap": 3744985595.65,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 4642,
        "name": "Hedera",
        "symbol": "HBAR",
        "slug": "hedera",
        "num_market_pairs": 166,
        "date_added": "2019-09-17T00:00:00.000Z",
        "tags": [
            "dag",
            "marketplace",
            "enterprise-solutions",
            "payments",
            "dcg-portfolio",
            "hedera-hashgraph-ecosystem",
            "layer-1"
        ],
        "max_supply": 50000000000,
        "circulating_supply": 32559526344.530865,
        "total_supply": 50000000000,
        "infinite_supply": false,
        "platform": null,
        "cmc_rank": 30,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.05896280956556764,
                "volume_24h": 71690050.73565531,
                "volume_change_24h": -55.8566,
                "percent_change_1h": -0.58523531,
                "percent_change_24h": -6.7031423,
                "percent_change_7d": 10.21678334,
                "percent_change_30d": 23.51999083,
                "percent_change_60d": 31.7359206,
                "percent_change_90d": 16.91673304,
                "market_cap": 1919801151.397656,
                "market_cap_dominance": 0.1631,
                "fully_diluted_market_cap": 2948140478.28,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 2280,
        "name": "Filecoin",
        "symbol": "FIL",
        "slug": "filecoin",
        "num_market_pairs": 347,
        "date_added": "2017-12-13T00:00:00.000Z",
        "tags": [
            "mineable",
            "distributed-computing",
            "filesharing",
            "storage",
            "polychain-capital-portfolio",
            "blockchain-capital-portfolio",
            "boostvc-portfolio",
            "dcg-portfolio",
            "hashkey-capital-portfolio",
            "a16z-portfolio",
            "winklevoss-capital-portfolio",
            "pantera-capital-portfolio",
            "web3",
            "filecoin-ecosystem",
            "sec-security-token",
            "alleged-sec-securities"
        ],
        "max_supply": null,
        "circulating_supply": 440026014,
        "total_supply": 1961641205,
        "infinite_supply": false,
        "platform": null,
        "cmc_rank": 31,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 4.131737405363029,
                "volume_24h": 177719178.84224796,
                "volume_change_24h": 122.2279,
                "percent_change_1h": -0.08412718,
                "percent_change_24h": -0.44844503,
                "percent_change_7d": -2.65441009,
                "percent_change_30d": -4.35847258,
                "percent_change_60d": 21.36234753,
                "percent_change_90d": -3.60902437,
                "market_cap": 1818071941.3765957,
                "market_cap_dominance": 0.1545,
                "fully_diluted_market_cap": 8104986342.6,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 8916,
        "name": "Internet Computer",
        "symbol": "ICP",
        "slug": "internet-computer",
        "num_market_pairs": 163,
        "date_added": "2021-03-23T00:00:00.000Z",
        "tags": [
            "platform",
            "distributed-computing",
            "collectibles-nfts",
            "defi",
            "interoperability",
            "smart-contracts",
            "dao",
            "wallet",
            "storage",
            "polychain-capital-portfolio",
            "lending-borowing",
            "metaverse",
            "electric-capital-portfolio",
            "fenbushi-capital-portfolio",
            "hashkey-capital-portfolio",
            "a16z-portfolio",
            "multicoin-capital-portfolio",
            "exnetwork-capital-portfolio",
            "web3",
            "internet-computer-ecosystem",
            "bitcoin-ecosystem",
            "sec-security-token",
            "alleged-sec-securities"
        ],
        "max_supply": null,
        "circulating_supply": 440949919.18431956,
        "total_supply": 503293167.05539423,
        "infinite_supply": true,
        "platform": null,
        "cmc_rank": 32,
        "self_reported_circulating_supply": 440949919.18431956,
        "self_reported_market_cap": 1794845575.193773,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 4.070406858252575,
                "volume_24h": 17673227.95913653,
                "volume_change_24h": -23.197,
                "percent_change_1h": -0.21640653,
                "percent_change_24h": -0.58302283,
                "percent_change_7d": -1.60708116,
                "percent_change_30d": 0.20504461,
                "percent_change_60d": 11.33235253,
                "percent_change_90d": -18.60873331,
                "market_cap": 1794845575.193773,
                "market_cap_dominance": 0.1525,
                "fully_diluted_market_cap": 2048607958.89,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 8000,
        "name": "Lido DAO",
        "symbol": "LDO",
        "slug": "lido-dao",
        "num_market_pairs": 227,
        "date_added": "2020-12-15T00:00:00.000Z",
        "tags": [
            "defi",
            "dao",
            "three-arrows-capital-portfolio",
            "paradigm-portfolio",
            "arbitrum-ecosytem",
            "liquid-staking-derivatives",
            "optimism-ecosystem"
        ],
        "max_supply": 1000000000,
        "circulating_supply": 878825666.6652696,
        "total_supply": 1000000000,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x5a98fcbea516cf06857215779fd812ca3bef1b32"
        },
        "infinite_supply": false,
        "cmc_rank": 33,
        "self_reported_circulating_supply": 878825659.5319883,
        "self_reported_market_cap": 1671416947.9059517,
        "tvl_ratio": 0.11120668,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 1.9018754513791185,
                "volume_24h": 81082456.23905466,
                "volume_change_24h": 3.7253,
                "percent_change_1h": -0.3426146,
                "percent_change_24h": 1.98485246,
                "percent_change_7d": 3.33726797,
                "percent_change_30d": -2.19688057,
                "percent_change_60d": 5.80011342,
                "percent_change_90d": 17.93439132,
                "market_cap": 1671416961.4725645,
                "market_cap_dominance": 0.142,
                "fully_diluted_market_cap": 1901875451.38,
                "tvl": 15029824846.25497,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 21794,
        "name": "Aptos",
        "symbol": "APT",
        "slug": "aptos",
        "num_market_pairs": 253,
        "date_added": "2022-10-19T01:50:25.000Z",
        "tags": [
            "binance-labs-portfolio",
            "aptos-ecosystem",
            "circle-ventures-portfolio",
            "layer-1"
        ],
        "max_supply": null,
        "circulating_supply": 219967392.9866234,
        "total_supply": 1049076091.0046234,
        "infinite_supply": true,
        "platform": null,
        "cmc_rank": 34,
        "self_reported_circulating_supply": 130000000,
        "self_reported_market_cap": 966967640.2165049,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 7.438212617050038,
                "volume_24h": 716245781.3141936,
                "volume_change_24h": 755.4134,
                "percent_change_1h": 0.63947599,
                "percent_change_24h": 11.21867872,
                "percent_change_7d": 8.00742431,
                "percent_change_30d": 4.76984435,
                "percent_change_60d": 26.28183194,
                "percent_change_90d": -5.26681822,
                "market_cap": 1636164237.8527062,
                "market_cap_dominance": 0.139,
                "fully_diluted_market_cap": 7803251016.36,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 27075,
        "name": "Mantle",
        "symbol": "MNT",
        "slug": "mantle",
        "num_market_pairs": 13,
        "date_added": "2023-07-17T11:00:31.000Z",
        "tags": [
            "ethereum-ecosystem",
            "layer-2"
        ],
        "max_supply": 6219316768,
        "circulating_supply": 3234268324.7631407,
        "total_supply": 6219316794.99,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x3c3a81e81dc49a522a592e7622a7e711c06bf354"
        },
        "infinite_supply": false,
        "cmc_rank": 35,
        "self_reported_circulating_supply": 1843669839,
        "self_reported_market_cap": 858907008.9858328,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.4658681238999391,
                "volume_24h": 15105702.38273631,
                "volume_change_24h": -67.5334,
                "percent_change_1h": 0.06641697,
                "percent_change_24h": -1.91232642,
                "percent_change_7d": -10.41174316,
                "percent_change_30d": -3.18436006,
                "percent_change_60d": -3.18436006,
                "percent_change_90d": -3.18436006,
                "market_cap": 1506742516.6464033,
                "market_cap_dominance": 0.128,
                "fully_diluted_market_cap": 2897381434.65,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 11841,
        "name": "Arbitrum",
        "symbol": "ARB",
        "slug": "arbitrum",
        "num_market_pairs": 445,
        "date_added": "2023-03-23T12:50:11.000Z",
        "tags": [
            "scaling",
            "dao",
            "dapp",
            "ethereum-ecosystem",
            "polychain-capital-portfolio",
            "layer-2",
            "rollups",
            "pantera-capital-portfolio",
            "arbitrum-ecosytem"
        ],
        "max_supply": null,
        "circulating_supply": 1275000000,
        "total_supply": 10000000000,
        "platform": {
            "id": 11841,
            "name": "Arbitrum",
            "symbol": "ARB",
            "slug": "arbitrum",
            "token_address": "0x912CE59144191C1204E64559FE8253a0e49E6548"
        },
        "infinite_supply": false,
        "cmc_rank": 36,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 1.1761471210164751,
                "volume_24h": 206978912.55946177,
                "volume_change_24h": 7.9248,
                "percent_change_1h": -0.32484978,
                "percent_change_24h": 0.36094904,
                "percent_change_7d": 4.05533804,
                "percent_change_30d": 4.59774833,
                "percent_change_60d": 19.02588053,
                "percent_change_90d": 8.87998168,
                "market_cap": 1499587579.2960057,
                "market_cap_dominance": 0.1274,
                "fully_diluted_market_cap": 11761471210.16,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 3635,
        "name": "Cronos",
        "symbol": "CRO",
        "slug": "cronos",
        "num_market_pairs": 194,
        "date_added": "2018-12-14T00:00:00.000Z",
        "tags": [
            "medium-of-exchange",
            "cosmos-ecosystem",
            "centralized-exchange",
            "mobile",
            "payments",
            "injective-ecosystem"
        ],
        "max_supply": 30263013692,
        "circulating_supply": 25263013692,
        "total_supply": 30263013692,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b"
        },
        "infinite_supply": false,
        "cmc_rank": 37,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.05750127735383446,
                "volume_24h": 8705579.97631737,
                "volume_change_24h": 13.3948,
                "percent_change_1h": -0.073368,
                "percent_change_24h": -1.00437735,
                "percent_change_7d": -3.04778109,
                "percent_change_30d": 2.14483269,
                "percent_change_60d": 5.60676995,
                "percent_change_90d": -7.28612074,
                "market_cap": 1452655557.0974095,
                "market_cap_dominance": 0.1234,
                "fully_diluted_market_cap": 1740161943.87,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 3077,
        "name": "VeChain",
        "symbol": "VET",
        "slug": "vechain",
        "num_market_pairs": 232,
        "date_added": "2017-08-22T00:00:00.000Z",
        "tags": [
            "logistics",
            "data-provenance",
            "iot",
            "smart-contracts",
            "fenbushi-capital-portfolio"
        ],
        "max_supply": 86712634466,
        "circulating_supply": 72714516834,
        "total_supply": 85985041177,
        "infinite_supply": false,
        "platform": null,
        "cmc_rank": 38,
        "self_reported_circulating_supply": 72714516834,
        "self_reported_market_cap": 1294188317.6699798,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.017798211059072054,
                "volume_24h": 27781994.73770169,
                "volume_change_24h": -2.739,
                "percent_change_1h": 0.18816825,
                "percent_change_24h": -0.34565307,
                "percent_change_7d": -2.16110689,
                "percent_change_30d": -4.74597167,
                "percent_change_60d": 15.42536889,
                "percent_change_90d": -5.13354144,
                "market_cap": 1294188317.6699798,
                "market_cap_dominance": 0.11,
                "fully_diluted_market_cap": 1543329769.71,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 6535,
        "name": "NEAR Protocol",
        "symbol": "NEAR",
        "slug": "near-protocol",
        "num_market_pairs": 272,
        "date_added": "2020-08-11T00:00:00.000Z",
        "tags": [
            "platform",
            "staking",
            "coinbase-ventures-portfolio",
            "three-arrows-capital-portfolio",
            "arrington-xrp-capital-portfolio",
            "coinfund-portfolio",
            "electric-capital-portfolio",
            "fabric-ventures-portfolio",
            "kenetic-capital-portfolio",
            "near-protocol-ecosystem",
            "injective-ecosystem",
            "circle-ventures-portfolio",
            "sec-security-token",
            "alleged-sec-securities"
        ],
        "max_supply": null,
        "circulating_supply": 941671517,
        "total_supply": 1000000000,
        "infinite_supply": false,
        "platform": null,
        "cmc_rank": 39,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 1.3417876708389627,
                "volume_24h": 41506113.47575194,
                "volume_change_24h": -10.5123,
                "percent_change_1h": 0.02884474,
                "percent_change_24h": -1.02293999,
                "percent_change_7d": -4.8300969,
                "percent_change_30d": 0.4819665,
                "percent_change_60d": 12.21954008,
                "percent_change_90d": -13.85182559,
                "market_cap": 1263523231.4908226,
                "market_cap_dominance": 0.1074,
                "fully_diluted_market_cap": 1341787670.84,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 3155,
        "name": "Quant",
        "symbol": "QNT",
        "slug": "quant",
        "num_market_pairs": 166,
        "date_added": "2018-08-10T00:00:00.000Z",
        "tags": [
            "platform",
            "interoperability"
        ],
        "max_supply": 14881364,
        "circulating_supply": 12072738,
        "total_supply": 14881364.27,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x4a220e6096b25eadb88358cb44068a3248254675"
        },
        "infinite_supply": false,
        "cmc_rank": 40,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 101.98058788760589,
                "volume_24h": 12343405.63367258,
                "volume_change_24h": -4.1631,
                "percent_change_1h": 0.0093688,
                "percent_change_24h": -0.11561368,
                "percent_change_7d": -2.79609834,
                "percent_change_30d": -0.70052596,
                "percent_change_60d": -0.46321281,
                "percent_change_90d": -1.83094094,
                "market_cap": 1231184918.6530395,
                "market_cap_dominance": 0.1046,
                "fully_diluted_market_cap": 1517610249.29,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 1518,
        "name": "Maker",
        "symbol": "MKR",
        "slug": "maker",
        "num_market_pairs": 358,
        "date_added": "2017-01-29T00:00:00.000Z",
        "tags": [
            "store-of-value",
            "defi",
            "dao",
            "polychain-capital-portfolio",
            "governance",
            "lending-borowing",
            "dragonfly-capital-portfolio",
            "electric-capital-portfolio",
            "a16z-portfolio",
            "1confirmation-portfolio",
            "placeholder-ventures-portfolio",
            "pantera-capital-portfolio",
            "paradigm-portfolio",
            "near-protocol-ecosystem",
            "arbitrum-ecosytem",
            "spartan-group",
            "bnb-chain",
            "real-world-assets"
        ],
        "max_supply": 1005577,
        "circulating_supply": 977631.03695089,
        "total_supply": 977631.03695089,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2"
        },
        "infinite_supply": false,
        "cmc_rank": 41,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": 0.21316518,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 1230.9100038468744,
                "volume_24h": 75500989.94925693,
                "volume_change_24h": 7.0184,
                "percent_change_1h": 1.23889348,
                "percent_change_24h": 2.35547273,
                "percent_change_7d": -6.19484708,
                "percent_change_30d": 34.03478699,
                "percent_change_60d": 104.0451901,
                "percent_change_90d": 98.61754197,
                "market_cap": 1203375823.4540439,
                "market_cap_dominance": 0.1023,
                "fully_diluted_market_cap": 1237774788.94,
                "tvl": 5645274001.52932,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 11840,
        "name": "Optimism",
        "symbol": "OP",
        "slug": "optimism-ethereum",
        "num_market_pairs": 303,
        "date_added": "2022-05-31T16:19:46.000Z",
        "tags": [
            "layer-2",
            "optimism-ecosystem"
        ],
        "max_supply": 4294967296,
        "circulating_supply": 716708907,
        "total_supply": 4294967296,
        "platform": {
            "id": 11840,
            "name": "Optimism",
            "symbol": "OP",
            "slug": "optimism-ethereum",
            "token_address": "0x4200000000000000000000000000000000000042"
        },
        "infinite_supply": false,
        "cmc_rank": 42,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 1.5668061316769397,
                "volume_24h": 187570531.14618436,
                "volume_change_24h": -0.812,
                "percent_change_1h": 0.34139978,
                "percent_change_24h": -7.08743352,
                "percent_change_7d": -5.51199213,
                "percent_change_30d": 28.57046424,
                "percent_change_60d": 46.95272933,
                "percent_change_90d": 2.58373465,
                "market_cap": 1122943910.1150775,
                "market_cap_dominance": 0.0954,
                "fully_diluted_market_cap": 6729381094.72,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 6719,
        "name": "The Graph",
        "symbol": "GRT",
        "slug": "the-graph",
        "num_market_pairs": 317,
        "date_added": "2020-12-17T00:00:00.000Z",
        "tags": [
            "ai-big-data",
            "enterprise-solutions",
            "defi",
            "coinbase-ventures-portfolio",
            "solana-ecosystem",
            "analytics",
            "coinfund-portfolio",
            "dcg-portfolio",
            "fabric-ventures-portfolio",
            "framework-ventures-portfolio",
            "ledgerprime-portfolio",
            "multicoin-capital-portfolio",
            "parafi-capital",
            "polygon-ecosystem",
            "fantom-ecosystem",
            "web3",
            "near-protocol-ecosystem",
            "arbitrum-ecosytem",
            "spartan-group",
            "injective-ecosystem"
        ],
        "max_supply": null,
        "circulating_supply": 9125277205.660192,
        "total_supply": 10721916086.988317,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0xc944e90c64b2c07662a292be6244bdf05cda44a7"
        },
        "infinite_supply": true,
        "cmc_rank": 43,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.10831038224409037,
                "volume_24h": 61453083.82368349,
                "volume_change_24h": -6.7437,
                "percent_change_1h": -0.09421607,
                "percent_change_24h": 0.34098847,
                "percent_change_7d": -1.05666355,
                "percent_change_30d": -5.69371861,
                "percent_change_60d": 7.85843306,
                "percent_change_90d": -2.48829653,
                "market_cap": 988362262.2283403,
                "market_cap_dominance": 0.084,
                "fully_diluted_market_cap": 1161294829.77,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 7278,
        "name": "Aave",
        "symbol": "AAVE",
        "slug": "aave",
        "num_market_pairs": 647,
        "date_added": "2020-10-02T00:00:00.000Z",
        "tags": [
            "defi",
            "dao",
            "yield-farming",
            "three-arrows-capital-portfolio",
            "governance",
            "lending-borowing",
            "blockchain-capital-portfolio",
            "defiance-capital-portfolio",
            "framework-ventures-portfolio",
            "alameda-research-portfolio",
            "pantera-capital-portfolio",
            "parafi-capital",
            "polygon-ecosystem",
            "fantom-ecosystem",
            "optimism-ecosystem"
        ],
        "max_supply": 16000000,
        "circulating_supply": 14511855.95984754,
        "total_supply": 16000000,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9"
        },
        "infinite_supply": false,
        "cmc_rank": 44,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": 0.19808471,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 67.4220200482321,
                "volume_24h": 76517693.32882985,
                "volume_change_24h": -1.1813,
                "percent_change_1h": 0.29050601,
                "percent_change_24h": 1.96061216,
                "percent_change_7d": 5.17377341,
                "percent_change_30d": -3.77886887,
                "percent_change_60d": 29.76940079,
                "percent_change_90d": 10.05589118,
                "market_cap": 978418643.4618973,
                "market_cap_dominance": 0.0831,
                "fully_diluted_market_cap": 1078752320.77,
                "tvl": 4939395089.09425,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 2634,
        "name": "XDC Network",
        "symbol": "XDC",
        "slug": "xdc-network",
        "num_market_pairs": 61,
        "date_added": "2018-04-12T00:00:00.000Z",
        "tags": [
            "enterprise-solutions",
            "smart-contracts",
            "xdc-ecosystem",
            "layer-1"
        ],
        "max_supply": null,
        "circulating_supply": 13855171051.2,
        "total_supply": 37705012698.75,
        "infinite_supply": false,
        "platform": null,
        "cmc_rank": 45,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.06369425022763996,
                "volume_24h": 17360373.11268052,
                "volume_change_24h": 3.98,
                "percent_change_1h": 0.43802293,
                "percent_change_24h": -6.29448351,
                "percent_change_7d": -22.53751654,
                "percent_change_30d": 102.52179804,
                "percent_change_60d": 86.93535182,
                "percent_change_90d": 85.01593406,
                "market_cap": 882494731.8818861,
                "market_cap_dominance": 0.075,
                "fully_diluted_market_cap": 2401592513.67,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 4030,
        "name": "Algorand",
        "symbol": "ALGO",
        "slug": "algorand",
        "num_market_pairs": 342,
        "date_added": "2019-06-20T00:00:00.000Z",
        "tags": [
            "pos",
            "platform",
            "research",
            "smart-contracts",
            "arrington-xrp-capital-portfolio",
            "kenetic-capital-portfolio",
            "usv-portfolio",
            "multicoin-capital-portfolio",
            "exnetwork-capital-portfolio",
            "layer-1",
            "sec-security-token",
            "alleged-sec-securities"
        ],
        "max_supply": 10000000000,
        "circulating_supply": 7810457896.690244,
        "total_supply": 7810457896.690244,
        "infinite_supply": false,
        "platform": null,
        "cmc_rank": 46,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.11259622607337356,
                "volume_24h": 37235814.38916419,
                "volume_change_24h": -55.7961,
                "percent_change_1h": -0.28598388,
                "percent_change_24h": 0.5806983,
                "percent_change_7d": 5.24123058,
                "percent_change_30d": 2.15158308,
                "percent_change_60d": 2.78114807,
                "percent_change_90d": -29.41430797,
                "market_cap": 879428083.0723004,
                "market_cap_dominance": 0.0747,
                "fully_diluted_market_cap": 1125962260.73,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 6783,
        "name": "Axie Infinity",
        "symbol": "AXS",
        "slug": "axie-infinity",
        "num_market_pairs": 339,
        "date_added": "2020-08-31T00:00:00.000Z",
        "tags": [
            "collectibles-nfts",
            "gaming",
            "binance-launchpad",
            "metaverse",
            "defiance-capital-portfolio",
            "play-to-earn",
            "animoca-brands-portfolio",
            "sec-security-token",
            "alleged-sec-securities"
        ],
        "max_supply": 270000000,
        "circulating_supply": 140506242.30627233,
        "total_supply": 270000000,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0xbb0e17ef65f82ab018d8edd776e8dd940327b28b"
        },
        "infinite_supply": false,
        "cmc_rank": 47,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 5.929536154345287,
                "volume_24h": 35044822.98795863,
                "volume_change_24h": -22.4527,
                "percent_change_1h": -0.19997137,
                "percent_change_24h": 0.3945837,
                "percent_change_7d": 0.55010486,
                "percent_change_30d": 1.93676447,
                "percent_change_60d": 19.53676578,
                "percent_change_90d": -10.77416453,
                "market_cap": 833136843.6662412,
                "market_cap_dominance": 0.0708,
                "fully_diluted_market_cap": 1600974761.67,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 6210,
        "name": "The Sandbox",
        "symbol": "SAND",
        "slug": "the-sandbox",
        "num_market_pairs": 389,
        "date_added": "2020-08-05T00:00:00.000Z",
        "tags": [
            "collectibles-nfts",
            "content-creation",
            "gaming",
            "ethereum-ecosystem",
            "binance-launchpad",
            "metaverse",
            "play-to-earn",
            "animoca-brands-portfolio",
            "sec-security-token",
            "alleged-sec-securities"
        ],
        "max_supply": 3000000000,
        "circulating_supply": 2057931926.2233226,
        "total_supply": 3000000000,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x3845badAde8e6dFF049820680d1F14bD3903a5d0"
        },
        "infinite_supply": false,
        "cmc_rank": 48,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.4047322801900281,
                "volume_24h": 71299782.15802677,
                "volume_change_24h": -1.0283,
                "percent_change_1h": -0.22779825,
                "percent_change_24h": -0.47622236,
                "percent_change_7d": -1.78149086,
                "percent_change_30d": -3.06386269,
                "percent_change_60d": 7.14297554,
                "percent_change_90d": -16.89635809,
                "market_cap": 832911480.976222,
                "market_cap_dominance": 0.0708,
                "fully_diluted_market_cap": 1214196840.57,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 4847,
        "name": "Stacks",
        "symbol": "STX",
        "slug": "stacks",
        "num_market_pairs": 110,
        "date_added": "2019-10-28T00:00:00.000Z",
        "tags": [
            "mineable",
            "platform",
            "collectibles-nfts",
            "defi",
            "smart-contracts",
            "metaverse",
            "arrington-xrp-capital-portfolio",
            "blockchain-capital-portfolio",
            "dcg-portfolio",
            "fabric-ventures-portfolio",
            "hashkey-capital-portfolio",
            "huobi-capital-portfolio",
            "usv-portfolio",
            "web3",
            "injective-ecosystem",
            "bitcoin-ecosystem"
        ],
        "max_supply": 1818000000,
        "circulating_supply": 1398009274.914309,
        "total_supply": 1818000000,
        "infinite_supply": false,
        "platform": null,
        "cmc_rank": 49,
        "self_reported_circulating_supply": 1048765672.087772,
        "self_reported_market_cap": 612950842.9074398,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.5844497576729814,
                "volume_24h": 31659195.17255245,
                "volume_change_24h": -41.7941,
                "percent_change_1h": 0.25502912,
                "percent_change_24h": -0.94817999,
                "percent_change_7d": -5.92049327,
                "percent_change_30d": -9.2997077,
                "percent_change_60d": 12.20302764,
                "percent_change_90d": -5.46223007,
                "market_cap": 817066181.9482483,
                "market_cap_dominance": 0.0694,
                "fully_diluted_market_cap": 1062529659.45,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 6892,
        "name": "MultiversX",
        "symbol": "EGLD",
        "slug": "multiversx-egld",
        "num_market_pairs": 168,
        "date_added": "2020-09-04T00:00:00.000Z",
        "tags": [
            "binance-launchpad",
            "binance-labs-portfolio",
            "electric-capital-portfolio",
            "exnetwork-capital-portfolio",
            "elrond-ecosystem",
            "injective-ecosystem",
            "bnb-chain",
            "layer-1"
        ],
        "max_supply": 31415926,
        "circulating_supply": 25814552,
        "total_supply": 25819704,
        "infinite_supply": false,
        "platform": null,
        "cmc_rank": 50,
        "self_reported_circulating_supply": 25814552,
        "self_reported_market_cap": 796873470.1303012,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 30.869157447717907,
                "volume_24h": 14459620.20216823,
                "volume_change_24h": -70.5101,
                "percent_change_1h": -0.00325294,
                "percent_change_24h": -1.49310979,
                "percent_change_7d": -1.38495582,
                "percent_change_30d": -13.01797923,
                "percent_change_60d": 4.19193461,
                "percent_change_90d": -15.72779743,
                "market_cap": 796873470.1303012,
                "market_cap_dominance": 0.0677,
                "fully_diluted_market_cap": 969783166.06,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 1765,
        "name": "EOS",
        "symbol": "EOS",
        "slug": "eos",
        "num_market_pairs": 556,
        "date_added": "2017-07-01T00:00:00.000Z",
        "tags": [
            "medium-of-exchange",
            "enterprise-solutions",
            "smart-contracts",
            "eos",
            "fenbushi-capital-portfolio",
            "galaxy-digital-portfolio"
        ],
        "max_supply": null,
        "circulating_supply": 1099264780.692,
        "total_supply": 1099264780.692,
        "infinite_supply": true,
        "platform": null,
        "cmc_rank": 51,
        "self_reported_circulating_supply": 1099261439.2025,
        "self_reported_market_cap": 789918668.2042183,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.718590355336483,
                "volume_24h": 100166276.53241113,
                "volume_change_24h": 17.2432,
                "percent_change_1h": -0.13674055,
                "percent_change_24h": -0.548774,
                "percent_change_7d": -1.29131227,
                "percent_change_30d": -2.02249694,
                "percent_change_60d": 5.92547671,
                "percent_change_90d": -17.03282855,
                "market_cap": 789921069.3663453,
                "market_cap_dominance": 0.0671,
                "fully_diluted_market_cap": 789921069.37,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 10603,
        "name": "Immutable",
        "symbol": "IMX",
        "slug": "immutable-x",
        "num_market_pairs": 166,
        "date_added": "2021-06-24T00:00:00.000Z",
        "tags": [
            "collectibles-nfts",
            "zero-knowledge-proofs",
            "gaming",
            "scaling",
            "layer-2",
            "rollups",
            "arrington-xrp-capital-portfolio",
            "alameda-research-portfolio"
        ],
        "max_supply": 2000000000,
        "circulating_supply": 1089903324.9500241,
        "total_supply": 2000000000,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0xf57e7e7c23978c3caec3c3548e3d615c346e79ff"
        },
        "infinite_supply": false,
        "cmc_rank": 52,
        "self_reported_circulating_supply": 570354831,
        "self_reported_market_cap": 410694613.8790351,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.7200686161612175,
                "volume_24h": 15792496.25116654,
                "volume_change_24h": -5.9064,
                "percent_change_1h": -0.38350436,
                "percent_change_24h": -1.44924079,
                "percent_change_7d": -4.41148166,
                "percent_change_30d": 2.90911844,
                "percent_change_60d": 19.68845787,
                "percent_change_90d": 0.84830083,
                "market_cap": 784805178.9462737,
                "market_cap_dominance": 0.0667,
                "fully_diluted_market_cap": 1440137232.32,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 2011,
        "name": "Tezos",
        "symbol": "XTZ",
        "slug": "tezos",
        "num_market_pairs": 291,
        "date_added": "2017-10-06T00:00:00.000Z",
        "tags": [
            "pos",
            "platform",
            "enterprise-solutions",
            "collectibles-nfts",
            "defi",
            "smart-contracts",
            "polychain-capital-portfolio",
            "boostvc-portfolio",
            "winklevoss-capital-portfolio",
            "layer-1"
        ],
        "max_supply": null,
        "circulating_supply": 948286892.966439,
        "total_supply": 969442336.320918,
        "infinite_supply": true,
        "platform": null,
        "cmc_rank": 53,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.7933225439267659,
                "volume_24h": 14552407.84590861,
                "volume_change_24h": 3.9572,
                "percent_change_1h": -0.15708085,
                "percent_change_24h": -1.06370364,
                "percent_change_7d": -5.20343275,
                "percent_change_30d": -2.80999531,
                "percent_change_60d": 10.65882643,
                "percent_change_90d": -9.86976954,
                "market_cap": 752297370.3005441,
                "market_cap_dominance": 0.0639,
                "fully_diluted_market_cap": 769080460.44,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 19891,
        "name": "USDD",
        "symbol": "USDD",
        "slug": "usdd",
        "num_market_pairs": 145,
        "date_added": "2022-05-02T08:07:12.000Z",
        "tags": [
            "stablecoin",
            "algorithmic-stablecoin",
            "arbitrum-ecosytem",
            "usd-stablecoin"
        ],
        "max_supply": null,
        "circulating_supply": 743018860.222887,
        "total_supply": 743018860.222887,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x0c10bf8fcb7bf5412187a595ab97a3609160b5c6"
        },
        "infinite_supply": false,
        "cmc_rank": 54,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.9982666120949083,
                "volume_24h": 23430119.38107467,
                "volume_change_24h": 1.7606,
                "percent_change_1h": 0.02206974,
                "percent_change_24h": -0.08000342,
                "percent_change_7d": -0.03895136,
                "percent_change_30d": -0.08508609,
                "percent_change_60d": 0.24567667,
                "percent_change_90d": 0.07307015,
                "market_cap": 741730920.3173217,
                "market_cap_dominance": 0.063,
                "fully_diluted_market_cap": 741730920.32,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 2416,
        "name": "Theta Network",
        "symbol": "THETA",
        "slug": "theta-network",
        "num_market_pairs": 152,
        "date_added": "2018-01-17T00:00:00.000Z",
        "tags": [
            "media",
            "collectibles-nfts",
            "content-creation",
            "defi",
            "video",
            "metaverse",
            "huobi-capital-portfolio",
            "web3"
        ],
        "max_supply": 1000000000,
        "circulating_supply": 1000000000,
        "total_supply": 1000000000,
        "infinite_supply": false,
        "platform": null,
        "cmc_rank": 55,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.7386583182383473,
                "volume_24h": 11654535.04780014,
                "volume_change_24h": -8.6122,
                "percent_change_1h": 0.05808641,
                "percent_change_24h": 0.50699444,
                "percent_change_7d": -3.10679366,
                "percent_change_30d": 0.63374297,
                "percent_change_60d": 14.33982672,
                "percent_change_90d": -15.94982428,
                "market_cap": 738658318.2383473,
                "market_cap_dominance": 0.0628,
                "fully_diluted_market_cap": 738658318.24,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 2586,
        "name": "Synthetix",
        "symbol": "SNX",
        "slug": "synthetix",
        "num_market_pairs": 437,
        "date_added": "2018-03-14T00:00:00.000Z",
        "tags": [
            "services",
            "decentralized-exchange-dex-token",
            "defi",
            "derivatives",
            "dao",
            "yield-farming",
            "coinbase-ventures-portfolio",
            "three-arrows-capital-portfolio",
            "governance",
            "synthetics",
            "defiance-capital-portfolio",
            "framework-ventures-portfolio",
            "alameda-research-portfolio",
            "parafi-capital",
            "paradigm-portfolio",
            "fantom-ecosystem",
            "spartan-group",
            "bnb-chain",
            "optimism-ecosystem",
            "real-world-assets",
            "celsius-bankruptcy-estate"
        ],
        "max_supply": 308069419,
        "circulating_supply": 268428012.95894834,
        "total_supply": 322530361.0887736,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f"
        },
        "infinite_supply": false,
        "cmc_rank": 56,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 2.6011036060595845,
                "volume_24h": 40145690.37433568,
                "volume_change_24h": -13.0267,
                "percent_change_1h": -0.35577234,
                "percent_change_24h": -0.57793566,
                "percent_change_7d": 6.11370338,
                "percent_change_30d": 27.11114575,
                "percent_change_60d": 49.33582403,
                "percent_change_90d": 31.97255949,
                "market_cap": 698209072.4749295,
                "market_cap_dominance": 0.0593,
                "fully_diluted_market_cap": 801320476.68,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 1966,
        "name": "Decentraland",
        "symbol": "MANA",
        "slug": "decentraland",
        "num_market_pairs": 391,
        "date_added": "2017-09-17T00:00:00.000Z",
        "tags": [
            "platform",
            "collectibles-nfts",
            "gaming",
            "payments",
            "metaverse",
            "boostvc-portfolio",
            "dcg-portfolio",
            "fabric-ventures-portfolio",
            "kenetic-capital-portfolio",
            "polygon-ecosystem",
            "play-to-earn",
            "sec-security-token",
            "alleged-sec-securities"
        ],
        "max_supply": null,
        "circulating_supply": 1893095370.6427295,
        "total_supply": 2193179327.320146,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x0f5d2fb29fb7d3cfee444a200298f468908cc942"
        },
        "infinite_supply": false,
        "cmc_rank": 57,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.3666282325190422,
                "volume_24h": 42990581.32944501,
                "volume_change_24h": 8.0454,
                "percent_change_1h": -0.10328659,
                "percent_change_24h": -1.14947,
                "percent_change_7d": -1.86176971,
                "percent_change_30d": -5.05281918,
                "percent_change_60d": 7.74657625,
                "percent_change_90d": -17.2191733,
                "market_cap": 694062209.728725,
                "market_cap_dominance": 0.059,
                "fully_diluted_market_cap": 804081460.37,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 3602,
        "name": "Bitcoin SV",
        "symbol": "BSV",
        "slug": "bitcoin-sv",
        "num_market_pairs": 203,
        "date_added": "2018-11-09T00:00:00.000Z",
        "tags": [
            "mineable",
            "pow",
            "sha-256",
            "medium-of-exchange",
            "store-of-value",
            "state-channel",
            "bitcoin-ecosystem"
        ],
        "max_supply": 21000000,
        "circulating_supply": 19266076.64423905,
        "total_supply": 19266076.64423905,
        "infinite_supply": false,
        "platform": null,
        "cmc_rank": 58,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 35.76413428988248,
                "volume_24h": 516970757.382818,
                "volume_change_24h": 1072.0616,
                "percent_change_1h": -0.26635101,
                "percent_change_24h": -0.98271923,
                "percent_change_7d": -1.2245301,
                "percent_change_30d": -11.29986971,
                "percent_change_60d": 40.37716832,
                "percent_change_90d": 0.256507,
                "market_cap": 689034552.3437337,
                "market_cap_dominance": 0.0585,
                "fully_diluted_market_cap": 751046820.09,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 18876,
        "name": "ApeCoin",
        "symbol": "APE",
        "slug": "apecoin-ape",
        "num_market_pairs": 299,
        "date_added": "2022-03-17T12:26:36.000Z",
        "tags": [
            "collectibles-nfts",
            "content-creation",
            "gaming",
            "entertainment",
            "dao",
            "governance",
            "metaverse",
            "animoca-brands-portfolio"
        ],
        "max_supply": 1000000000,
        "circulating_supply": 368593750,
        "total_supply": 1000000000,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x4d224452801aced8b2f0aebe155379bb5d594381"
        },
        "infinite_supply": false,
        "cmc_rank": 59,
        "self_reported_circulating_supply": 368593750,
        "self_reported_market_cap": 676284751.3433386,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 1.8347699909272432,
                "volume_24h": 49433778.15831491,
                "volume_change_24h": -27.6897,
                "percent_change_1h": 0.30034811,
                "percent_change_24h": -1.06339512,
                "percent_change_7d": 2.14690428,
                "percent_change_30d": -4.38589363,
                "percent_change_60d": -18.29891,
                "percent_change_90d": -42.34944081,
                "market_cap": 676284751.3433386,
                "market_cap_dominance": 0.0575,
                "fully_diluted_market_cap": 1834769990.93,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 3513,
        "name": "Fantom",
        "symbol": "FTM",
        "slug": "fantom",
        "num_market_pairs": 483,
        "date_added": "2018-10-29T00:00:00.000Z",
        "tags": [
            "platform",
            "enterprise-solutions",
            "defi",
            "research",
            "scaling",
            "smart-contracts",
            "fantom-ecosystem",
            "injective-ecosystem",
            "layer-1"
        ],
        "max_supply": 3175000000,
        "circulating_supply": 2801908844.632703,
        "total_supply": 3175000000,
        "infinite_supply": false,
        "platform": null,
        "cmc_rank": 60,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.23714735803755987,
                "volume_24h": 42224081.34477389,
                "volume_change_24h": -0.8307,
                "percent_change_1h": -0.13686946,
                "percent_change_24h": -0.00314563,
                "percent_change_7d": -0.02392874,
                "percent_change_30d": -14.59136213,
                "percent_change_60d": 0.38998545,
                "percent_change_90d": -34.48028236,
                "market_cap": 664465279.9667172,
                "market_cap_dominance": 0.0565,
                "fully_diluted_market_cap": 752942861.77,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 7226,
        "name": "Injective",
        "symbol": "INJ",
        "slug": "injective",
        "num_market_pairs": 170,
        "date_added": "2020-09-29T00:00:00.000Z",
        "tags": [
            "pos",
            "platform",
            "cosmos-ecosystem",
            "ai-big-data",
            "defi",
            "smart-contracts",
            "cosmos",
            "binance-smart-chain",
            "binance-launchpad",
            "binance-labs-portfolio",
            "cms-holdings-portfolio",
            "pantera-capital-portfolio",
            "web3"
        ],
        "max_supply": 100000000,
        "circulating_supply": 83755555.66,
        "total_supply": 100000000,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0xe28b3b32b6c345a34ff64674606124dd5aceca30"
        },
        "infinite_supply": false,
        "cmc_rank": 61,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 7.622555248642031,
                "volume_24h": 33151669.75878303,
                "volume_change_24h": 2.9887,
                "percent_change_1h": -1.98495241,
                "percent_change_24h": -5.67627854,
                "percent_change_7d": -4.63619942,
                "percent_change_30d": -7.30533454,
                "percent_change_60d": 34.06181764,
                "percent_change_90d": 32.77830573,
                "market_cap": 638431350.3990628,
                "market_cap_dominance": 0.0542,
                "fully_diluted_market_cap": 762255524.86,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 5690,
        "name": "Render",
        "symbol": "RNDR",
        "slug": "render",
        "num_market_pairs": 164,
        "date_added": "2020-06-11T00:00:00.000Z",
        "tags": [
            "art",
            "media",
            "vr-ar",
            "ai-big-data",
            "distributed-computing",
            "collectibles-nfts",
            "gaming",
            "scaling",
            "video",
            "ethereum-ecosystem",
            "solana-ecosystem",
            "metaverse",
            "alameda-research-portfolio",
            "multicoin-capital-portfolio",
            "polygon-ecosystem",
            "web3"
        ],
        "max_supply": 536870912,
        "circulating_supply": 369614008.10879594,
        "total_supply": 530962614.97878325,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x6de037ef9ad2725eb40118bb1702ebb27e4aeb24"
        },
        "infinite_supply": false,
        "cmc_rank": 62,
        "self_reported_circulating_supply": 364833198,
        "self_reported_market_cap": 620301047.545714,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 1.7002319167942441,
                "volume_24h": 29837973.63841775,
                "volume_change_24h": 24.2757,
                "percent_change_1h": 1.122282,
                "percent_change_24h": 2.84208072,
                "percent_change_7d": -3.80347984,
                "percent_change_30d": -12.2619372,
                "percent_change_60d": -11.75817182,
                "percent_change_90d": 0.25533913,
                "market_cap": 628429533.4808214,
                "market_cap_dominance": 0.0534,
                "fully_diluted_market_cap": 912805059.78,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 1376,
        "name": "Neo",
        "symbol": "NEO",
        "slug": "neo",
        "num_market_pairs": 275,
        "date_added": "2016-09-08T00:00:00.000Z",
        "tags": [
            "platform",
            "enterprise-solutions",
            "smart-contracts"
        ],
        "max_supply": 100000000,
        "circulating_supply": 70538831,
        "total_supply": 100000000,
        "infinite_supply": false,
        "platform": null,
        "cmc_rank": 63,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 8.47115177014543,
                "volume_24h": 305693581.75219905,
                "volume_change_24h": 1213.8488,
                "percent_change_1h": -0.24279715,
                "percent_change_24h": -0.48391248,
                "percent_change_7d": -1.58724466,
                "percent_change_30d": -5.38370193,
                "percent_change_60d": 11.91476296,
                "percent_change_90d": -4.73524038,
                "market_cap": 597545143.0896394,
                "market_cap_dominance": 0.0508,
                "fully_diluted_market_cap": 847115177.01,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 4558,
        "name": "Flow",
        "symbol": "FLOW",
        "slug": "flow",
        "num_market_pairs": 141,
        "date_added": "2021-01-27T00:00:00.000Z",
        "tags": [
            "platform",
            "collectibles-nfts",
            "staking",
            "coinbase-ventures-portfolio",
            "coinfund-portfolio",
            "dcg-portfolio",
            "ledgerprime-portfolio",
            "a16z-portfolio",
            "animoca-brands-portfolio",
            "layer-1",
            "sec-security-token",
            "alleged-sec-securities"
        ],
        "max_supply": null,
        "circulating_supply": 1036200000,
        "total_supply": 1449305592,
        "infinite_supply": true,
        "platform": null,
        "cmc_rank": 64,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.5527276829173621,
                "volume_24h": 21234272.03958631,
                "volume_change_24h": -28.5941,
                "percent_change_1h": -0.06587869,
                "percent_change_24h": -1.04793921,
                "percent_change_7d": -3.2117434,
                "percent_change_30d": -8.696647,
                "percent_change_60d": 10.08157334,
                "percent_change_90d": -24.71174638,
                "market_cap": 572736425.0389706,
                "market_cap_dominance": 0.0487,
                "fully_diluted_market_cap": 801071321.71,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 10791,
        "name": "eCash",
        "symbol": "XEC",
        "slug": "ecash",
        "num_market_pairs": 74,
        "date_added": "2021-07-08T00:00:00.000Z",
        "tags": [
            "layer-1"
        ],
        "max_supply": 21000000000000,
        "circulating_supply": 19466360923093,
        "total_supply": 19466360923093,
        "infinite_supply": false,
        "platform": null,
        "cmc_rank": 65,
        "self_reported_circulating_supply": 19466354673093,
        "self_reported_market_cap": 571303335.1287651,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.000029348244431117776,
                "volume_24h": 8631639.65523671,
                "volume_change_24h": -74.3949,
                "percent_change_1h": -0.25659462,
                "percent_change_24h": -1.17586343,
                "percent_change_7d": -1.72937259,
                "percent_change_30d": -12.64907064,
                "percent_change_60d": 47.11211448,
                "percent_change_90d": 13.60980197,
                "market_cap": 571303518.5552928,
                "market_cap_dominance": 0.0485,
                "fully_diluted_market_cap": 616313133.05,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 7334,
        "name": "Conflux",
        "symbol": "CFX",
        "slug": "conflux-network",
        "num_market_pairs": 106,
        "date_added": "2020-11-11T00:00:00.000Z",
        "tags": [
            "mineable",
            "pow",
            "platform",
            "collectibles-nfts",
            "defi",
            "payments",
            "research",
            "smart-contracts",
            "staking",
            "hybrid-pow-dpos",
            "huobi-capital-portfolio",
            "web3",
            "injective-ecosystem",
            "bnb-chain"
        ],
        "max_supply": null,
        "circulating_supply": 3165301210.788153,
        "total_supply": 5278164274,
        "infinite_supply": true,
        "platform": null,
        "cmc_rank": 66,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.18023463532950051,
                "volume_24h": 25877021.91975288,
                "volume_change_24h": 74.6163,
                "percent_change_1h": -1.38549949,
                "percent_change_24h": 1.25634629,
                "percent_change_7d": -3.0088709,
                "percent_change_30d": -1.11810029,
                "percent_change_60d": 0.52159796,
                "percent_change_90d": -28.45715248,
                "market_cap": 570496909.4344292,
                "market_cap_dominance": 0.0485,
                "fully_diluted_market_cap": 951308013.13,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 4846,
        "name": "Kava",
        "symbol": "KAVA",
        "slug": "kava",
        "num_market_pairs": 122,
        "date_added": "2019-10-25T00:00:00.000Z",
        "tags": [
            "cosmos-ecosystem",
            "defi",
            "binance-launchpad",
            "binance-labs-portfolio",
            "lending-borowing",
            "arrington-xrp-capital-portfolio",
            "framework-ventures-portfolio",
            "hashkey-capital-portfolio",
            "injective-ecosystem"
        ],
        "max_supply": null,
        "circulating_supply": 683968318,
        "total_supply": 683968317,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x0C356B7fD36a5357E5A017EF11887ba100C9AB76"
        },
        "infinite_supply": true,
        "cmc_rank": 67,
        "self_reported_circulating_supply": 683959463,
        "self_reported_market_cap": 565124538.5303942,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.8262544333426295,
                "volume_24h": 8467801.87097119,
                "volume_change_24h": -9.1755,
                "percent_change_1h": -0.12528904,
                "percent_change_24h": -0.42890882,
                "percent_change_7d": -3.30603416,
                "percent_change_30d": -19.00314186,
                "percent_change_60d": 4.45678075,
                "percent_change_90d": -2.09593637,
                "market_cap": 565131855.0134014,
                "market_cap_dominance": 0.048,
                "fully_diluted_market_cap": 565131854.19,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 2943,
        "name": "Rocket Pool",
        "symbol": "RPL",
        "slug": "rocket-pool",
        "num_market_pairs": 98,
        "date_added": "2018-07-17T00:00:00.000Z",
        "tags": [
            "defi",
            "liquid-staking-derivatives"
        ],
        "max_supply": null,
        "circulating_supply": 19620795.39271658,
        "total_supply": 19620795.39271658,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0xd33526068d116ce69f19a9ee46f0bd304f21a51f"
        },
        "infinite_supply": false,
        "cmc_rank": 68,
        "self_reported_circulating_supply": 19620795.39271658,
        "self_reported_market_cap": 545966369.7177533,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 27.825904036511236,
                "volume_24h": 10973922.46461233,
                "volume_change_24h": 3.489,
                "percent_change_1h": -0.24325116,
                "percent_change_24h": 7.88426021,
                "percent_change_7d": -4.09887729,
                "percent_change_30d": -23.00981119,
                "percent_change_60d": -36.58834681,
                "percent_change_90d": -37.11226483,
                "market_cap": 545966369.7177533,
                "market_cap_dominance": 0.0464,
                "fully_diluted_market_cap": 545966369.72,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 2087,
        "name": "KuCoin Token",
        "symbol": "KCS",
        "slug": "kucoin-token",
        "num_market_pairs": 39,
        "date_added": "2017-10-24T00:00:00.000Z",
        "tags": [
            "marketplace",
            "centralized-exchange",
            "discount-token",
            "algorand-ecosystem"
        ],
        "max_supply": 170118638,
        "circulating_supply": 96732986.425851,
        "total_supply": 144232986.425767,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0xf34960d9d60be18cc1d5afc1a6f012a723a28811"
        },
        "infinite_supply": false,
        "cmc_rank": 69,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 5.607052332762078,
                "volume_24h": 556856.34956993,
                "volume_change_24h": -39.709,
                "percent_change_1h": -0.07825559,
                "percent_change_24h": -0.48847199,
                "percent_change_7d": 0.75037888,
                "percent_change_30d": -9.86621226,
                "percent_change_60d": -4.55201703,
                "percent_change_90d": -24.96079221,
                "market_cap": 542386917.1941103,
                "market_cap_dominance": 0.0461,
                "fully_diluted_market_cap": 953864106.04,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 4066,
        "name": "Chiliz",
        "symbol": "CHZ",
        "slug": "chiliz",
        "num_market_pairs": 358,
        "date_added": "2019-07-01T00:00:00.000Z",
        "tags": [
            "sports",
            "collectibles-nfts",
            "content-creation",
            "payments",
            "layer-1",
            "sec-security-token",
            "alleged-sec-securities"
        ],
        "max_supply": 8888888888,
        "circulating_supply": 7108895988.022483,
        "total_supply": 8888888888,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x3506424f91fd33084466f402d5d97f05f8e3b4af"
        },
        "infinite_supply": false,
        "cmc_rank": 70,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.07583597262527139,
                "volume_24h": 24106476.45495598,
                "volume_change_24h": -16.5477,
                "percent_change_1h": -0.34989031,
                "percent_change_24h": 0.17557411,
                "percent_change_7d": -1.24658387,
                "percent_change_30d": -0.8943539,
                "percent_change_60d": 12.2836359,
                "percent_change_90d": -27.66106782,
                "market_cap": 539110041.5435747,
                "market_cap_dominance": 0.0458,
                "fully_diluted_market_cap": 674097534.38,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 6538,
        "name": "Curve DAO Token",
        "symbol": "CRV",
        "slug": "curve-dao-token",
        "num_market_pairs": 539,
        "date_added": "2020-08-14T00:00:00.000Z",
        "tags": [
            "decentralized-exchange-dex-token",
            "defi",
            "dao",
            "yield-farming",
            "amm",
            "governance",
            "framework-ventures-portfolio",
            "alameda-research-portfolio",
            "polygon-ecosystem",
            "fantom-ecosystem",
            "arbitrum-ecosytem",
            "optimism-ecosystem"
        ],
        "max_supply": 3303030299,
        "circulating_supply": 871067862,
        "total_supply": 1994084211.2123532,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0xD533a949740bb3306d119CC777fa900bA034cd52"
        },
        "infinite_supply": false,
        "cmc_rank": 71,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": 0.12383409,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.6151620083970545,
                "volume_24h": 68246820.20466103,
                "volume_change_24h": -5.6985,
                "percent_change_1h": -0.1795902,
                "percent_change_24h": 2.02060488,
                "percent_change_7d": 6.7031452,
                "percent_change_30d": -23.95599729,
                "percent_change_60d": -7.66735486,
                "percent_change_90d": -22.21485215,
                "market_cap": 535847855.4380483,
                "market_cap_dominance": 0.0455,
                "fully_diluted_market_cap": 2031898752.53,
                "tvl": 4327143445.31388,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 7080,
        "name": "Gala",
        "symbol": "GALA",
        "slug": "gala",
        "num_market_pairs": 282,
        "date_added": "2020-09-16T00:00:00.000Z",
        "tags": [
            "collectibles-nfts",
            "gaming",
            "binance-smart-chain",
            "polygon-ecosystem",
            "play-to-earn"
        ],
        "max_supply": 50000000000,
        "circulating_supply": 22646638043.71472,
        "total_supply": 25175284038.321808,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0xd1d2Eb1B1e90B638588728b4130137D262C87cae"
        },
        "infinite_supply": false,
        "cmc_rank": 72,
        "self_reported_circulating_supply": 25188252124.427498,
        "self_reported_market_cap": 584159433.9026431,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.023191741571306844,
                "volume_24h": 52744798.75557452,
                "volume_change_24h": -14.318,
                "percent_change_1h": -0.37787715,
                "percent_change_24h": -1.0988268,
                "percent_change_7d": -0.15589207,
                "percent_change_30d": -6.154534,
                "percent_change_60d": 10.83804301,
                "percent_change_90d": -18.60211268,
                "market_cap": 525214976.9687578,
                "market_cap_dominance": 0.0446,
                "fully_diluted_market_cap": 1159587078.57,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 3330,
        "name": "Pax Dollar",
        "symbol": "USDP",
        "slug": "paxos-standard",
        "num_market_pairs": 98,
        "date_added": "2018-09-27T00:00:00.000Z",
        "tags": [
            "store-of-value",
            "stablecoin",
            "asset-backed-stablecoin",
            "bnb-chain",
            "usd-stablecoin"
        ],
        "max_supply": null,
        "circulating_supply": 511100005.2006834,
        "total_supply": 511100005.2006834,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x8e870d67f660d95d5be530380d0ec0bd388289e1"
        },
        "infinite_supply": false,
        "cmc_rank": 73,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 1.0000285393120276,
                "volume_24h": 2233861.46239007,
                "volume_change_24h": -78.5422,
                "percent_change_1h": 0.05760813,
                "percent_change_24h": -0.40821621,
                "percent_change_7d": 0.67095808,
                "percent_change_30d": 0.64405639,
                "percent_change_60d": 0.38397673,
                "percent_change_90d": 0.84206331,
                "market_cap": 511114591.64320916,
                "market_cap_dominance": 0.0434,
                "fully_diluted_market_cap": 511114591.64,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 4256,
        "name": "Klaytn",
        "symbol": "KLAY",
        "slug": "klaytn",
        "num_market_pairs": 212,
        "date_added": "2020-03-30T00:00:00.000Z",
        "tags": [
            "platform",
            "enterprise-solutions",
            "injective-ecosystem"
        ],
        "max_supply": null,
        "circulating_supply": 3196824851.0609,
        "total_supply": 5775777573.0609,
        "infinite_supply": true,
        "platform": null,
        "cmc_rank": 74,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.15587356190153648,
                "volume_24h": 6359443.68557193,
                "volume_change_24h": 10.3821,
                "percent_change_1h": -0.10333967,
                "percent_change_24h": -0.8470029,
                "percent_change_7d": -1.52895201,
                "percent_change_30d": -5.12927479,
                "percent_change_60d": 4.44466728,
                "percent_change_90d": -14.93528448,
                "market_cap": 498300476.31021136,
                "market_cap_dominance": 0.0423,
                "fully_diluted_market_cap": 900291023.06,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 24478,
        "name": "Pepe",
        "symbol": "PEPE",
        "slug": "pepe",
        "num_market_pairs": 229,
        "date_added": "2023-04-17T06:18:08.000Z",
        "tags": [
            "memes"
        ],
        "max_supply": 420690000000000,
        "circulating_supply": 391790000000000,
        "total_supply": 420690000000000,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x6982508145454ce325ddbe47a25d4ec3d2311933"
        },
        "infinite_supply": false,
        "cmc_rank": 75,
        "self_reported_circulating_supply": 420690000000000,
        "self_reported_market_cap": 519537953.2684308,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.000001234966253698521,
                "volume_24h": 165866637.0328124,
                "volume_change_24h": 13.2666,
                "percent_change_1h": 0.74360607,
                "percent_change_24h": 4.16941207,
                "percent_change_7d": 1.45641751,
                "percent_change_30d": -18.41924647,
                "percent_change_60d": 35.40644792,
                "percent_change_90d": -0.04261629,
                "market_cap": 483847428.53654355,
                "market_cap_dominance": 0.0411,
                "fully_diluted_market_cap": 519537953.27,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 1437,
        "name": "Zcash",
        "symbol": "ZEC",
        "slug": "zcash",
        "num_market_pairs": 304,
        "date_added": "2016-10-29T00:00:00.000Z",
        "tags": [
            "mineable",
            "pow",
            "equihash",
            "medium-of-exchange",
            "privacy",
            "zero-knowledge-proofs",
            "binance-chain",
            "boostvc-portfolio",
            "dcg-portfolio",
            "electric-capital-portfolio",
            "fenbushi-capital-portfolio",
            "hashkey-capital-portfolio",
            "winklevoss-capital-portfolio",
            "placeholder-ventures-portfolio",
            "pantera-capital-portfolio",
            "bnb-chain"
        ],
        "max_supply": 21000000,
        "circulating_supply": 16328268.75,
        "total_supply": 16328268.75,
        "infinite_supply": false,
        "platform": null,
        "cmc_rank": 76,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 29.53403006074097,
                "volume_24h": 55917402.82148034,
                "volume_change_24h": 103.7887,
                "percent_change_1h": -0.00186788,
                "percent_change_24h": 0.15242141,
                "percent_change_7d": 0.00897788,
                "percent_change_30d": 2.28916489,
                "percent_change_60d": 24.88632583,
                "percent_change_90d": -7.78627218,
                "market_cap": 482239580.1023574,
                "market_cap_dominance": 0.041,
                "fully_diluted_market_cap": 620214631.28,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 1720,
        "name": "IOTA",
        "symbol": "MIOTA",
        "slug": "iota",
        "num_market_pairs": 95,
        "date_added": "2017-06-13T00:00:00.000Z",
        "tags": [
            "dag",
            "medium-of-exchange",
            "iot",
            "sharing-economy"
        ],
        "max_supply": 2779530283,
        "circulating_supply": 2779530283,
        "total_supply": 2779530283,
        "infinite_supply": false,
        "platform": null,
        "cmc_rank": 77,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.1731516891153918,
                "volume_24h": 5454686.7493979,
                "volume_change_24h": -5.896,
                "percent_change_1h": -0.09921506,
                "percent_change_24h": -0.48162049,
                "percent_change_7d": -0.16558476,
                "percent_change_30d": -3.63534276,
                "percent_change_60d": 10.52981123,
                "percent_change_90d": -0.48081218,
                "market_cap": 481280363.448833,
                "market_cap_dominance": 0.0409,
                "fully_diluted_market_cap": 481280363.45,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 4705,
        "name": "PAX Gold",
        "symbol": "PAXG",
        "slug": "pax-gold",
        "num_market_pairs": 121,
        "date_added": "2019-09-26T00:00:00.000Z",
        "tags": [
            "bnb-chain",
            "tokenized-gold"
        ],
        "max_supply": null,
        "circulating_supply": 248343.361,
        "total_supply": 248343.361,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x45804880de22913dafe09f4980848ece6ecbaf78"
        },
        "infinite_supply": true,
        "cmc_rank": 78,
        "self_reported_circulating_supply": 271181,
        "self_reported_market_cap": 521125383.307707,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 1921.6884048207912,
                "volume_24h": 6209221.955916,
                "volume_change_24h": -15.9368,
                "percent_change_1h": -0.00795637,
                "percent_change_24h": -0.58645449,
                "percent_change_7d": -0.56135426,
                "percent_change_30d": 0.58777089,
                "percent_change_60d": 0.04541166,
                "percent_change_90d": -4.84824256,
                "market_cap": 477238557.2479239,
                "market_cap_dominance": 0.0406,
                "fully_diluted_market_cap": 477238557.25,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 5176,
        "name": "Tether Gold",
        "symbol": "XAUt",
        "slug": "tether-gold",
        "num_market_pairs": 42,
        "date_added": "2020-02-07T00:00:00.000Z",
        "tags": [
            "tokenized-gold"
        ],
        "max_supply": null,
        "circulating_supply": 246524,
        "total_supply": 246524,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x68749665FF8D2d112Fa859AA293F07A622782F38"
        },
        "infinite_supply": false,
        "cmc_rank": 79,
        "self_reported_circulating_supply": 48450,
        "self_reported_market_cap": 93134391.3219039,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 1922.2784586564273,
                "volume_24h": 1078513.05489784,
                "volume_change_24h": 2.5199,
                "percent_change_1h": -0.00156357,
                "percent_change_24h": -0.37050752,
                "percent_change_7d": -0.79798914,
                "percent_change_30d": -0.25276479,
                "percent_change_60d": -1.757264,
                "percent_change_90d": -4.49945778,
                "market_cap": 473887774.7418171,
                "market_cap_dominance": 0.0403,
                "fully_diluted_market_cap": 473887774.74,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 4172,
        "name": "Terra Classic",
        "symbol": "LUNC",
        "slug": "terra-luna",
        "num_market_pairs": 421,
        "date_added": "2019-07-26T00:00:00.000Z",
        "tags": [
            "cosmos-ecosystem",
            "store-of-value",
            "defi",
            "payments",
            "coinbase-ventures-portfolio",
            "binance-labs-portfolio",
            "solana-ecosystem",
            "arrington-xrp-capital-portfolio",
            "hashkey-capital-portfolio",
            "kenetic-capital-portfolio",
            "huobi-capital-portfolio",
            "pantera-capital-portfolio",
            "terra-ecosystem",
            "injective-ecosystem",
            "sec-security-token",
            "alleged-sec-securities"
        ],
        "max_supply": null,
        "circulating_supply": 5814088848239.116,
        "total_supply": 6839130848052.573,
        "infinite_supply": true,
        "platform": null,
        "cmc_rank": 80,
        "self_reported_circulating_supply": 5814083798804.293,
        "self_reported_market_cap": 450477915.22836906,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.00007748046481906797,
                "volume_24h": 16461544.49235979,
                "volume_change_24h": -8.5358,
                "percent_change_1h": -0.1224957,
                "percent_change_24h": -1.10493946,
                "percent_change_7d": -2.91010485,
                "percent_change_30d": -7.52439595,
                "percent_change_60d": -12.2309992,
                "percent_change_90d": -6.98762142,
                "market_cap": 450478306.46092623,
                "market_cap_dominance": 0.0383,
                "fully_diluted_market_cap": 529899037.07,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 6953,
        "name": "Frax Share",
        "symbol": "FXS",
        "slug": "frax-share",
        "num_market_pairs": 192,
        "date_added": "2020-09-08T00:00:00.000Z",
        "tags": [
            "defi",
            "seigniorage",
            "avalanche-ecosystem",
            "exnetwork-capital-portfolio",
            "olympus-pro-ecosystem",
            "arbitrum-ecosytem",
            "liquid-staking-derivatives"
        ],
        "max_supply": null,
        "circulating_supply": 72818505.98352176,
        "total_supply": 99681752.7128909,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0"
        },
        "infinite_supply": false,
        "cmc_rank": 81,
        "self_reported_circulating_supply": 72818505.98352176,
        "self_reported_market_cap": 445722394.4417111,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 6.121004385101975,
                "volume_24h": 28482039.46762292,
                "volume_change_24h": 1.9875,
                "percent_change_1h": -0.24258942,
                "percent_change_24h": -0.51337626,
                "percent_change_7d": 1.00508904,
                "percent_change_30d": -3.48249264,
                "percent_change_60d": 24.50889509,
                "percent_change_90d": 1.1774628,
                "market_cap": 445722394.4417111,
                "market_cap_dominance": 0.0379,
                "fully_diluted_market_cap": 610152445.47,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 16086,
        "name": "BitTorrent(New)",
        "symbol": "BTT",
        "slug": "bittorrent-new",
        "num_market_pairs": 116,
        "date_added": "2019-01-31T00:00:00.000Z",
        "tags": [
            "platform",
            "distributed-computing",
            "filesharing",
            "interoperability",
            "staking",
            "tron-ecosystem",
            "storage",
            "binance-launchpad",
            "binance-labs-portfolio",
            "web3",
            "bnb-chain",
            "sec-security-token",
            "alleged-sec-securities"
        ],
        "max_supply": null,
        "circulating_supply": 951421714286000,
        "total_supply": 990000000000000,
        "platform": {
            "id": 1958,
            "name": "Tron20",
            "symbol": "TRX",
            "slug": "tron",
            "token_address": "TAFjULxiVgT4qWk6UZwjqwZXTSaGaqnVp4"
        },
        "infinite_supply": false,
        "cmc_rank": 82,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 4.648557482446291e-7,
                "volume_24h": 14500965.4270096,
                "volume_change_24h": 1.4525,
                "percent_change_1h": -0.01892371,
                "percent_change_24h": 0.17493326,
                "percent_change_7d": 0.30034033,
                "percent_change_30d": -2.22263271,
                "percent_change_60d": -11.73165257,
                "percent_change_90d": -21.896547,
                "market_cap": 442273852.8906062,
                "market_cap_dominance": 0.0376,
                "fully_diluted_market_cap": 460207190.76,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 11857,
        "name": "GMX",
        "symbol": "GMX",
        "slug": "gmx",
        "num_market_pairs": 218,
        "date_added": "2021-09-13T12:11:50.000Z",
        "tags": [
            "derivatives",
            "dex",
            "avalanche-ecosystem",
            "arbitrum-ecosytem"
        ],
        "max_supply": null,
        "circulating_supply": 8940348.24311751,
        "total_supply": 9321117.07111036,
        "platform": {
            "id": 11841,
            "name": "Arbitrum",
            "symbol": "ARB",
            "slug": "arbitrum",
            "token_address": "0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a"
        },
        "infinite_supply": true,
        "cmc_rank": 83,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 49.11935749372263,
                "volume_24h": 13471367.87601478,
                "volume_change_24h": -31.564,
                "percent_change_1h": -1.02284413,
                "percent_change_24h": -1.86938192,
                "percent_change_7d": -1.76944156,
                "percent_change_30d": -11.84125664,
                "percent_change_60d": 10.46095143,
                "percent_change_90d": -16.64066589,
                "market_cap": 439144161.472064,
                "market_cap_dominance": 0.0373,
                "fully_diluted_market_cap": 457847281.66,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 5692,
        "name": "Compound",
        "symbol": "COMP",
        "slug": "compound",
        "num_market_pairs": 387,
        "date_added": "2020-06-16T00:00:00.000Z",
        "tags": [
            "defi",
            "dao",
            "yield-farming",
            "coinbase-ventures-portfolio",
            "three-arrows-capital-portfolio",
            "polychain-capital-portfolio",
            "lending-borowing",
            "dragonfly-capital-portfolio",
            "alameda-research-portfolio",
            "a16z-portfolio",
            "pantera-capital-portfolio",
            "paradigm-portfolio",
            "arbitrum-ecosytem",
            "bnb-chain"
        ],
        "max_supply": 10000000,
        "circulating_supply": 7794974.28123286,
        "total_supply": 10000000,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0xc00e94cb662c3520282e6f5717214004a7f26888"
        },
        "infinite_supply": false,
        "cmc_rank": 84,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": 0.33439439,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 56.235036764951275,
                "volume_24h": 55768456.18132843,
                "volume_change_24h": -20.1728,
                "percent_change_1h": -0.50978683,
                "percent_change_24h": 1.06368408,
                "percent_change_7d": -6.08853848,
                "percent_change_30d": -7.52253549,
                "percent_change_60d": 113.6425499,
                "percent_change_90d": 60.63526218,
                "market_cap": 438350665.2869795,
                "market_cap_dominance": 0.0372,
                "fully_diluted_market_cap": 562350367.65,
                "tvl": 1310879259.41601,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 5899,
        "name": "Casper",
        "symbol": "CSPR",
        "slug": "casper",
        "num_market_pairs": 53,
        "date_added": "2020-07-21T00:00:00.000Z",
        "tags": [
            "enterprise-solutions",
            "arrington-xrp-capital-portfolio",
            "hashkey-capital-portfolio",
            "web3",
            "okex-blockdream-ventures-portfolio"
        ],
        "max_supply": null,
        "circulating_supply": 11233269000,
        "total_supply": 11924261675,
        "infinite_supply": false,
        "platform": null,
        "cmc_rank": 85,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.038711038409292776,
                "volume_24h": 4829009.72578849,
                "volume_change_24h": -15.7568,
                "percent_change_1h": 0.25570021,
                "percent_change_24h": -0.83218588,
                "percent_change_7d": 1.08912746,
                "percent_change_30d": 3.31302016,
                "percent_change_60d": -5.80299667,
                "percent_change_90d": -18.66901002,
                "market_cap": 434851507.7209179,
                "market_cap_dominance": 0.0369,
                "fully_diluted_market_cap": 461600551.7,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 8646,
        "name": "Mina",
        "symbol": "MINA",
        "slug": "mina",
        "num_market_pairs": 96,
        "date_added": "2021-03-02T00:00:00.000Z",
        "tags": [
            "pos",
            "zero-knowledge-proofs",
            "staking",
            "coinbase-ventures-portfolio",
            "three-arrows-capital-portfolio",
            "polychain-capital-portfolio"
        ],
        "max_supply": null,
        "circulating_supply": 948355632.8400393,
        "total_supply": 1061822332.8400393,
        "infinite_supply": true,
        "platform": null,
        "cmc_rank": 86,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.4561853294423183,
                "volume_24h": 11124854.0101525,
                "volume_change_24h": -37.5392,
                "percent_change_1h": -0.10551884,
                "percent_change_24h": 0.0148778,
                "percent_change_7d": 4.93346329,
                "percent_change_30d": -8.62595778,
                "percent_change_60d": 11.12456774,
                "percent_change_90d": -15.5575993,
                "market_cap": 432625926.79561156,
                "market_cap_dominance": 0.0368,
                "fully_diluted_market_cap": 484387770.72,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 20947,
        "name": "Sui",
        "symbol": "SUI",
        "slug": "sui",
        "num_market_pairs": 146,
        "date_added": "2022-07-12T08:03:11.000Z",
        "tags": [
            "binance-launchpool",
            "coinbase-ventures-portfolio",
            "binance-labs-portfolio",
            "electric-capital-portfolio",
            "a16z-portfolio",
            "layer-1"
        ],
        "max_supply": 10000000000,
        "circulating_supply": 723162190.4615384,
        "total_supply": 10000000000,
        "platform": {
            "id": 20947,
            "name": "SUI",
            "symbol": "SUI",
            "slug": "sui",
            "token_address": "0x2::sui::SUI"
        },
        "infinite_supply": false,
        "cmc_rank": 87,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.597205185024449,
                "volume_24h": 105828885.38428234,
                "volume_change_24h": 28.7204,
                "percent_change_1h": 0.07854735,
                "percent_change_24h": 0.59579606,
                "percent_change_7d": -2.11957192,
                "percent_change_30d": -9.97471965,
                "percent_change_60d": 0.34784793,
                "percent_change_90d": -42.66309857,
                "market_cap": 431876209.75726885,
                "market_cap_dominance": 0.0367,
                "fully_diluted_market_cap": 5972051850.24,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 2502,
        "name": "Huobi Token",
        "symbol": "HT",
        "slug": "huobi-token",
        "num_market_pairs": 119,
        "date_added": "2018-02-03T00:00:00.000Z",
        "tags": [
            "marketplace",
            "centralized-exchange",
            "discount-token",
            "payments",
            "algorand-ecosystem",
            "kenetic-capital-portfolio",
            "alameda-research-portfolio",
            "multicoin-capital-portfolio"
        ],
        "max_supply": 500000000,
        "circulating_supply": 162233844,
        "total_supply": 203980445,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x6f259637dcd74c767781e37bc6133cd6a68aa161"
        },
        "infinite_supply": false,
        "cmc_rank": 88,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 2.6132757986147968,
                "volume_24h": 17597648.07351392,
                "volume_change_24h": 9.0803,
                "percent_change_1h": -0.04678001,
                "percent_change_24h": 0.14021007,
                "percent_change_7d": -2.52038714,
                "percent_change_30d": -3.83711535,
                "percent_change_60d": -1.74884261,
                "percent_change_90d": -7.20116878,
                "market_cap": 423961778.24144834,
                "market_cap_dominance": 0.036,
                "fully_diluted_market_cap": 1306637899.31,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 4269,
        "name": "GateToken",
        "symbol": "GT",
        "slug": "gatetoken",
        "num_market_pairs": 26,
        "date_added": "2019-08-20T00:00:00.000Z",
        "tags": [
            "marketplace",
            "centralized-exchange",
            "discount-token",
            "algorand-ecosystem",
            "ftx-bankruptcy-estate"
        ],
        "max_supply": null,
        "circulating_supply": 96044284.92962714,
        "total_supply": 300000000,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0xe66747a101bff2dba3697199dcce5b743b454759"
        },
        "infinite_supply": false,
        "cmc_rank": 89,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 4.093998665643993,
                "volume_24h": 746248.11415536,
                "volume_change_24h": -34.427,
                "percent_change_1h": -0.06796329,
                "percent_change_24h": 0.13921593,
                "percent_change_7d": -1.23965442,
                "percent_change_30d": -4.27920246,
                "percent_change_60d": 1.09897969,
                "percent_change_90d": -16.04274411,
                "market_cap": 393205174.344625,
                "market_cap_dominance": 0.0334,
                "fully_diluted_market_cap": 1228199599.69,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 5964,
        "name": "Trust Wallet Token",
        "symbol": "TWT",
        "slug": "trust-wallet-token",
        "num_market_pairs": 161,
        "date_added": "2020-07-30T00:00:00.000Z",
        "tags": [
            "wallet",
            "polygon-ecosystem",
            "bnb-chain"
        ],
        "max_supply": 1000000000,
        "circulating_supply": 416649900,
        "total_supply": 999668148,
        "platform": {
            "id": 1839,
            "name": "BNB Smart Chain (BEP20)",
            "symbol": "BNB",
            "slug": "bnb",
            "token_address": "0x4b0f1812e5df2a09796481ff14017e6005508003"
        },
        "infinite_supply": false,
        "cmc_rank": 90,
        "self_reported_circulating_supply": 416649900,
        "self_reported_market_cap": 380399737.81719744,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.9129961097247292,
                "volume_24h": 4912636.45136885,
                "volume_change_24h": -53.2488,
                "percent_change_1h": 0.0615882,
                "percent_change_24h": -1.13286064,
                "percent_change_7d": -2.25516109,
                "percent_change_30d": 8.78556433,
                "percent_change_60d": 24.18282527,
                "percent_change_90d": -14.60794373,
                "market_cap": 380399737.81719744,
                "market_cap_dominance": 0.0323,
                "fully_diluted_market_cap": 912996109.72,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 131,
        "name": "Dash",
        "symbol": "DASH",
        "slug": "dash",
        "num_market_pairs": 384,
        "date_added": "2014-02-14T00:00:00.000Z",
        "tags": [
            "mineable",
            "hybrid-pow-pos",
            "x11",
            "medium-of-exchange",
            "privacy",
            "masternodes",
            "dao",
            "governance",
            "alleged-sec-securities"
        ],
        "max_supply": 18900000,
        "circulating_supply": 11402946.12981031,
        "total_supply": 11402946.12981031,
        "infinite_supply": false,
        "platform": null,
        "cmc_rank": 91,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 32.784938920883185,
                "volume_24h": 54096443.34808405,
                "volume_change_24h": -6.1665,
                "percent_change_1h": -0.08129853,
                "percent_change_24h": -0.69935418,
                "percent_change_7d": 3.4771994,
                "percent_change_30d": -2.02838288,
                "percent_change_60d": 13.66847296,
                "percent_change_90d": -16.78340935,
                "market_cap": 373844892.3839523,
                "market_cap_dominance": 0.0318,
                "fully_diluted_market_cap": 619635345.6,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 11865,
        "name": "Bone ShibaSwap",
        "symbol": "BONE",
        "slug": "bone-shibaswap",
        "num_market_pairs": 99,
        "date_added": "2021-09-13T19:17:01.000Z",
        "tags": [
            "memes",
            "doggone-doggerel"
        ],
        "max_supply": 250000000,
        "circulating_supply": 229923350.6228802,
        "total_supply": 230003022.82484713,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x9813037ee2218799597d83D4a5B6F3b6778218d9"
        },
        "infinite_supply": false,
        "cmc_rank": 92,
        "self_reported_circulating_supply": 229917843.45,
        "self_reported_market_cap": 371089732.092822,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 1.6140101460786471,
                "volume_24h": 9888744.7675828,
                "volume_change_24h": -10.9486,
                "percent_change_1h": -0.35192312,
                "percent_change_24h": -1.92479042,
                "percent_change_7d": 0.12706524,
                "percent_change_30d": 32.05360738,
                "percent_change_60d": 129.7161695,
                "percent_change_90d": 105.94217666,
                "market_cap": 371098620.72572684,
                "market_cap_dominance": 0.0315,
                "fully_diluted_market_cap": 403502536.52,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 2694,
        "name": "Nexo",
        "symbol": "NEXO",
        "slug": "nexo",
        "num_market_pairs": 100,
        "date_added": "2018-05-01T00:00:00.000Z",
        "tags": [
            "services",
            "payments",
            "cardano-ecosystem",
            "alleged-sec-securities"
        ],
        "max_supply": 1000000000,
        "circulating_supply": 560000011,
        "total_supply": 1000000000,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0xb62132e35a6c13ee1ee0f84dc5d40bad8d815206"
        },
        "infinite_supply": false,
        "cmc_rank": 93,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.6485436140843583,
                "volume_24h": 1847625.30355984,
                "volume_change_24h": 15.0036,
                "percent_change_1h": 0.04499534,
                "percent_change_24h": -0.35037554,
                "percent_change_7d": 0.27850209,
                "percent_change_30d": 4.97620738,
                "percent_change_60d": 5.56079909,
                "percent_change_90d": -3.17699767,
                "market_cap": 363184431.02122045,
                "market_cap_dominance": 0.0309,
                "fully_diluted_market_cap": 648543614.08,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 3306,
        "name": "Gemini Dollar",
        "symbol": "GUSD",
        "slug": "gemini-dollar",
        "num_market_pairs": 98,
        "date_added": "2018-10-05T00:00:00.000Z",
        "tags": [
            "store-of-value",
            "stablecoin",
            "asset-backed-stablecoin",
            "usd-stablecoin"
        ],
        "max_supply": 0,
        "circulating_supply": 360436744.95,
        "total_supply": 360436744.95,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x056Fd409E1d7A124BD7017459dFEa2F387b6d5Cd"
        },
        "infinite_supply": false,
        "cmc_rank": 94,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.9909363555084223,
                "volume_24h": 1260183.76671095,
                "volume_change_24h": 18.361,
                "percent_change_1h": 0.01782142,
                "percent_change_24h": 0.02895744,
                "percent_change_7d": 1.50290032,
                "percent_change_30d": -0.08883075,
                "percent_change_60d": -1.09990245,
                "percent_change_90d": -0.45077716,
                "market_cap": 357169874.43207175,
                "market_cap_dominance": 0.0303,
                "fully_diluted_market_cap": 357169874.43,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 4157,
        "name": "THORChain",
        "symbol": "RUNE",
        "slug": "thorchain",
        "num_market_pairs": 143,
        "date_added": "2019-07-23T00:00:00.000Z",
        "tags": [
            "cosmos-ecosystem",
            "decentralized-exchange-dex-token",
            "defi",
            "dex",
            "multicoin-capital-portfolio",
            "exnetwork-capital-portfolio",
            "injective-ecosystem"
        ],
        "max_supply": 500000000,
        "circulating_supply": 339429574,
        "total_supply": 486077749,
        "platform": {
            "id": 1839,
            "name": "BNB Beacon Chain (BEP2)",
            "symbol": "BNB",
            "slug": "bnb",
            "token_address": "RUNE-B1A"
        },
        "infinite_supply": false,
        "cmc_rank": 95,
        "self_reported_circulating_supply": 192781399,
        "self_reported_market_cap": 198843635.4122,
        "tvl_ratio": 3.70089674,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 1.0314461687883072,
                "volume_24h": 27938299.20099258,
                "volume_change_24h": -29.6971,
                "percent_change_1h": 0.38277872,
                "percent_change_24h": 3.78969724,
                "percent_change_7d": 11.23622682,
                "percent_change_30d": 1.54397513,
                "percent_change_60d": 10.51451915,
                "percent_change_90d": -10.47961212,
                "market_cap": 350103333.6757472,
                "market_cap_dominance": 0.0297,
                "fully_diluted_market_cap": 515723084.39,
                "tvl": 94599595.29368,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 5632,
        "name": "Arweave",
        "symbol": "AR",
        "slug": "arweave",
        "num_market_pairs": 91,
        "date_added": "2020-05-27T00:00:00.000Z",
        "tags": [
            "distributed-computing",
            "filesharing",
            "storage",
            "coinbase-ventures-portfolio",
            "solana-ecosystem",
            "arrington-xrp-capital-portfolio",
            "blockchain-capital-portfolio",
            "a16z-portfolio",
            "multicoin-capital-portfolio",
            "web3"
        ],
        "max_supply": 66000000,
        "circulating_supply": 65454185,
        "total_supply": 65454185,
        "infinite_supply": false,
        "platform": null,
        "cmc_rank": 96,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 5.210490911651067,
                "volume_24h": 3806548.69958258,
                "volume_change_24h": -4.679,
                "percent_change_1h": -0.88210639,
                "percent_change_24h": -0.53246454,
                "percent_change_7d": -3.05521481,
                "percent_change_30d": -11.99010445,
                "percent_change_60d": 8.32478817,
                "percent_change_90d": -17.40552484,
                "market_cap": 341048436.07202756,
                "market_cap_dominance": 0.029,
                "fully_diluted_market_cap": 343892400.17,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 11156,
        "name": "dYdX",
        "symbol": "DYDX",
        "slug": "dydx",
        "num_market_pairs": 258,
        "date_added": "2021-08-03T00:00:00.000Z",
        "tags": [
            "defi",
            "derivatives",
            "zero-knowledge-proofs",
            "three-arrows-capital-portfolio",
            "paradigm-portfolio",
            "spartan-group"
        ],
        "max_supply": 1000000000,
        "circulating_supply": 156256174,
        "total_supply": 1000000000,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x92d6c1e31e14520e676a687f0a93788b716beff5"
        },
        "infinite_supply": false,
        "cmc_rank": 97,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 2.132868969467387,
                "volume_24h": 46187357.22855641,
                "volume_change_24h": 21.5014,
                "percent_change_1h": 0.06379278,
                "percent_change_24h": 2.21172942,
                "percent_change_7d": 7.94975035,
                "percent_change_30d": 13.67674277,
                "percent_change_60d": 38.3159604,
                "percent_change_90d": 8.3634755,
                "market_cap": 333273944.8122967,
                "market_cap_dominance": 0.0283,
                "fully_diluted_market_cap": 2132868969.47,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 7501,
        "name": "WOO Network",
        "symbol": "WOO",
        "slug": "wootrade",
        "num_market_pairs": 196,
        "date_added": "2020-10-23T00:00:00.000Z",
        "tags": [
            "centralized-exchange",
            "three-arrows-capital-portfolio",
            "exnetwork-capital-portfolio",
            "polygon-ecosystem",
            "arbitrum-ecosytem",
            "mvb",
            "vbc-ventures-portfolio",
            "injective-ecosystem",
            "bnb-chain"
        ],
        "max_supply": null,
        "circulating_supply": 1733489990.0527875,
        "total_supply": 2250658857.901858,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x4691937a7508860f876c9c0a2a617e7d9e945d4b"
        },
        "infinite_supply": false,
        "cmc_rank": 98,
        "self_reported_circulating_supply": 1733489990.0527875,
        "self_reported_market_cap": 329493433.6814324,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.1900751868035874,
                "volume_24h": 10303818.62026442,
                "volume_change_24h": -13.2565,
                "percent_change_1h": 0.07139158,
                "percent_change_24h": 2.54517467,
                "percent_change_7d": -5.49065532,
                "percent_change_30d": -14.98802635,
                "percent_change_60d": 8.88869909,
                "percent_change_90d": -14.39550525,
                "market_cap": 329493433.6814324,
                "market_cap_dominance": 0.028,
                "fully_diluted_market_cap": 427794402.85,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 1697,
        "name": "Basic Attention Token",
        "symbol": "BAT",
        "slug": "basic-attention-token",
        "num_market_pairs": 394,
        "date_added": "2017-06-01T00:00:00.000Z",
        "tags": [
            "marketing",
            "content-creation",
            "defi",
            "payments",
            "dcg-portfolio",
            "1confirmation-portfolio",
            "pantera-capital-portfolio",
            "web3",
            "bnb-chain"
        ],
        "max_supply": 1500000000,
        "circulating_supply": 1489852086.7326057,
        "total_supply": 1500000000,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x0d8775f648430679a709e98d2b0cb6250d2887ef"
        },
        "infinite_supply": false,
        "cmc_rank": 99,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 0.2200497642120605,
                "volume_24h": 36578561.57547551,
                "volume_change_24h": -24.3257,
                "percent_change_1h": 0.35782027,
                "percent_change_24h": 3.26427897,
                "percent_change_7d": 8.54162831,
                "percent_change_30d": 15.57983002,
                "percent_change_60d": 30.81552304,
                "percent_change_90d": 6.33475923,
                "market_cap": 327841600.39635617,
                "market_cap_dominance": 0.0279,
                "fully_diluted_market_cap": 330074646.32,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    },
    {
        "id": 9816,
        "name": "APENFT",
        "symbol": "NFT",
        "slug": "apenft",
        "num_market_pairs": 70,
        "date_added": "2021-05-14T00:00:00.000Z",
        "tags": [
            "art",
            "collectibles-nfts",
            "tron-ecosystem"
        ],
        "max_supply": 999990000000000,
        "circulating_supply": 990105683025576.6,
        "total_supply": 999990000000000,
        "platform": {
            "id": 1027,
            "name": "Ethereum",
            "symbol": "ETH",
            "slug": "ethereum",
            "token_address": "0x198d14f2ad9ce69e76ea330b374de4957c3f850a"
        },
        "infinite_supply": false,
        "cmc_rank": 100,
        "self_reported_circulating_supply": null,
        "self_reported_market_cap": null,
        "tvl_ratio": null,
        "last_updated": "2023-08-10T04:38:00.000Z",
        "quote": {
            "USD": {
                "price": 3.2334537399110625e-7,
                "volume_24h": 20717565.93934688,
                "volume_change_24h": 12.7639,
                "percent_change_1h": -0.06742659,
                "percent_change_24h": 0.62149878,
                "percent_change_7d": 1.83284495,
                "percent_change_30d": -0.83468026,
                "percent_change_60d": 3.69402068,
                "percent_change_90d": -8.74944845,
                "market_cap": 320146092.3686248,
                "market_cap_dominance": 0.0272,
                "fully_diluted_market_cap": 323342140.54,
                "tvl": null,
                "last_updated": "2023-08-10T04:38:00.000Z"
            }
        }
    }
]


export const tokonomicsDummyData=[
    {
        "_id": "64cb42568d745038c3f6040e",
        "token_name": "Token Supply",
        "token_supply": "500000000000000",
        "createdAt": "2023-08-03T05:59:50.437Z",
        "updatedAt": "2023-08-08T16:40:43.101Z",
        "__v": 0
    },
    {
        "_id": "64cb42568d745038c3f60410",
        "token_name": "Token Name",
        "token_supply": "$chepa",
        "createdAt": "2023-08-03T05:59:50.790Z",
        "updatedAt": "2023-08-04T10:39:01.662Z",
        "__v": 0
    },
    {
        "_id": "64cb43bf8d745038c3f6041d",
        "token_name": "Token Symbol",
        "token_supply": "Chepa",
        "createdAt": "2023-08-03T06:05:51.561Z",
        "updatedAt": "2023-08-04T10:39:33.189Z",
        "__v": 0
    },
    {
        "_id": "64cba28591c201dc2a2d2df3",
        "token_name": "Token Decimal",
        "token_supply": "18",
        "createdAt": "2023-08-03T12:50:13.274Z",
        "updatedAt": "2023-08-04T10:39:48.278Z",
        "__v": 0
    },
    {
        "_id": "64ccd580240ec417e07571c7",
        "token_name": "Burn",
        "token_supply": "5%",
        "createdAt": "2023-08-04T10:40:00.812Z",
        "updatedAt": "2023-08-04T10:40:00.812Z",
        "__v": 0
    },
    {
        "_id": "64ccd58f240ec417e07571ca",
        "token_name": "Liqudity",
        "token_supply": "90%",
        "createdAt": "2023-08-04T10:40:15.387Z",
        "updatedAt": "2023-08-04T10:40:29.876Z",
        "__v": 0
    },
    {
        "_id": "64ccd5b5240ec417e07571d1",
        "token_name": "Centalized Exchange",
        "token_supply": "4%",
        "createdAt": "2023-08-04T10:40:53.882Z",
        "updatedAt": "2023-08-05T10:30:40.038Z",
        "__v": 0
    }
]