import React, { useEffect } from "react";
import { InputDiv, MainSection, SubmitBtn, Title } from "./elements";
import { Loader, MainContainer, ToastMessage } from "components/common";
import { Input } from "antd";
import { Form } from "react-bootstrap";
import { LeaveFeedbackHook } from "hooks/adminHooks";
import { useFormik } from "formik";

const validate = (values) => {
  const errors: any = {};

  if (!values.email) {
    errors.email = "Email is Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.message) {
    errors.message = "Message is Required";
  }

  return errors;
};

const FeedBackComp = () => {
  const { data, leaveFeedback, loading } = LeaveFeedbackHook();
  console.log("dddd", data);
  const {
    handleSubmit,
    setFieldValue,
    errors,
    values,
    handleChange,
    resetForm,
  } = useFormik({
    initialValues: {
      email: "",
      message: "",
    },
    validate,
    onSubmit: (values) => {
      // Handle form submission here
      console.log(values);
      const to = values.email;
      const msg = values.message;
      leaveFeedback({ to, msg });
      resetForm();
    },
  });

  console.log("eee", errors, values);

  useEffect(() => {
    if (data) {
      ToastMessage(data?.message, "", "success");

      resetForm();
    }
  }, [data]);

  return (
    <MainSection>
      {loading && <Loader content="Loading " />}
      <MainContainer>
        <Title>leave your feedback</Title>

        <InputDiv>
          <Form.Control
            type="email"
            placeholder="Enter Email..."
            value={values.email}
            onChange={handleChange}
            name="email"
          />
        </InputDiv>
        {errors.email ? (
          <p className="text-center text-danger mt-4">
            {errors.email?.toString()}
          </p>
        ) : null}
        <InputDiv>
          <Form.Control
            type="text"
            name="message"
            placeholder="Enter Message..."
            value={values.message}
            onChange={handleChange}
          />
        </InputDiv>
        {errors.message ? (
          <p className="text-center text-danger mt-4">
            {errors.message?.toString()}
          </p>
        ) : null}
        <SubmitBtn type="submit" onClick={() => handleSubmit()}>
          Submit
        </SubmitBtn>
      </MainContainer>
    </MainSection>
  );
};

export default FeedBackComp;
