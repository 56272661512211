import React, { useEffect } from "react";
import { Modal, Input, Button } from "antd";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { CreateTokonomicsHook } from "hooks/adminHooks";

const validationSchema = Yup.object().shape({
  token_name: Yup.string().required("Token name is required"),
  token_supply: Yup.string()
  .required("Token supply is required"),
});

const ModalContent = styled.div`
  background: #fff;
  padding: 1rem;
`;

const AddTokonmicsModal = ({
  modalVisible,
  setModalVisible,
  setIsDataAdded,
}) => {
  const hideModal = () => {
    setModalVisible(false);
  };

  const { createTokonomics, data, loading } = CreateTokonomicsHook();

  const handleSubmit = (values, { resetForm }) => {
    console.log(values);

    createTokonomics(values);

    // Reset the form after successful submission
    resetForm();
  };

  useEffect(() => {
    if (data) {
      setIsDataAdded(true);
      hideModal();
    }
  }, [data, loading]);

  return (
    <div>
      <Modal
        title="Token Details"
        visible={modalVisible}
        onCancel={hideModal}
        footer={null}
      >
        <ModalContent>
          <Formik
            initialValues={{ token_name: "", token_supply: "" }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {(formikProps) => (
              <form onSubmit={formikProps.handleSubmit}>
                <div>
                  <label htmlFor="token_name">Token Name</label>
                  <Field
                    as={Input}
                    id="token_name"
                    name="token_name"
                    placeholder="Enter token name"
                  />
                  <ErrorMessage name="token_name" component="div" />
                </div>

                <div>
                  <label htmlFor="token_supply">Token Supply</label>
                  <Field
                    as={Input}
                    type="text"
                    id="token_supply"
                    name="token_supply"
                    placeholder="Enter token supply"
                  />
                  <ErrorMessage name="token_supply" component="div" />
                </div>

                <div className="my-2">
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default AddTokonmicsModal;
