import env from "../../environment";
export const APIPath = {
  server: "http://16.170.228.185:8080",
  moralisServer:'https://nbnob0befkpo.usemoralis.com:2053/server',

  //admin
  login: "login",
  logout: "blacklist-token",
  auth: "auth",
  updatePassword: "update-password",
  updateUsername: "update-username",

  //cmc
  getCrypto: 'get-cryptocurrency'


};
