import styled from "styled-components";

export const MainWrapper = styled.div`
  overflow: hidden;
  .container {
    padding-bottom: 6rem;
  }
`;

export const Title = styled.h5`
  color: #fff;
  text-align: center;
  margin-top: 4rem;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.5px; /* 42.969% */

  @media (max-width: 990px) {
    font-size: 40px;
  }
`;

export const DataWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6rem;
  gap: 5rem;
  /* padding-bottom: 6rem; */
  border: 2px solid #4bae32;
  border-radius: 15px;
  padding: 1rem 3rem;
  background-color: #0d1522;
  margin-bottom: 2rem;

  overflow-x: auto;

  @media (max-width: 767px) {
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    margin-top: 4rem;
  }

  h5 {
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }

  p {
    color: #4bae32;
    font-family: Readex Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.5px; /* 196.429% */
    margin-bottom: 0rem;
    text-align: center;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  .negative-value {
    color: red;
  }

  .positive-value {
    color: green; /* or any other color you prefer for positive values */
  }

  .dataaa {
    margin-left: 2rem;
  }
`;
