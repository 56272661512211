import styled, { createGlobalStyle } from "styled-components";
const GlobalStyle = createGlobalStyle`
  * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Readex Pro', sans-serif;
  font-family: 'Passion One', cursive;
  } 
 body
{
  overflow-x: hidden !important;
  font-family: 'Readex Pro', sans-serif;
  font-family: 'Passion One', cursive;
  background: #070E19;
}

iframe
{
  position: absolute !important;
  width: none !important;
  top: none !important;
  left: none !important;
  right: none !important;
  z-index: -5 !important;
}

/* Loader */
.cover-spin {
 position: fixed;
 width: 100%;
 left: 0;
 right: 0;
 top: 0;
 bottom: 0;
 background:rgba(0,0,0,0.4);
 z-index: 999999;
 display: flex;
 justify-content: center;
 align-items: center;
 color:white;
}


 
/* Models styles  */
.ant-modal-footer
  {
    display: none;
  }
  .ant-modal-close
  {
    display: none;
  }
  .ant-modal-content
  {
   background: none;
  }
  .ant-modal-body
  {
    padding: 0px;
  }
  .ant-modal-mask
  {
    background-color: rgb(0 0 0 / 78%) ;
  }
  .closeModelInput
  {
    transform: translateY(-2px);
  }
    .ant-space-align-baseline
  {
    display: flex;
    justify-content:center  ;
  }
  .modalInputMale
  {
    border-top-right-radius:7px;
  border-bottom-right-radius:7px;
  transform: translateX(-6px);
  }
  .ant-form-vertical .ant-form-item-label>label {
  
 
    font-weight:600;
}

  /* Models styles */

  .transak_modal-content
  {
    width:auto !important;
    height:auto !important;
     
  }

  @media(max-width:990px)
  {
    .transak_modal-content
  {
    // width:300px !important;
    // height:300px !important;
  }

  }
   
   .ant-select-dropdown 
{
  background: #0A1F2F !important;
  border: 0.5px solid #a3aab5 !important;
}

.ant-select-dropdown .ant-select-item-option-content
{
  color: #ffffff !important;
  font-size:20px !important ;
  background: #0A1F2F !important;
  padding-left: 5px;
  &:hover
  {
    background-color: #4bae32 !important;
    transform:scale(1.1) ;
    transition:.3s all ease-in-out ;
    /* color: #000 !important; */
  }
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
{
  background: #0A1F2F !important;
} 

.ant-select-item-option-active:not(.ant-select-item-option-disabled)
{
  background: #0A1F2F !important;
}

.tableBtnAdd
{
  border:none;
  border-radius:5px;
  padding:0.3rem 2rem;
  background:#2b2b2b;
  color:white; 
}

.tableBtnAdds
{
  border:none;
  border-radius:5px;
  padding:0.7rem 1rem;
  background:#2b2b2b;
  color:white; 
  width:10rem
}

.arrow
{
  position: fixed;
  bottom: 25px;
  right: 10px;
  z-index: 100;
  background: #4bae32;
border-radius: 4px;
width: 32px;
height: 32px;
justify-content: center;
align-items: center;
text-align: center;
display: flex;
cursor: pointer;
}

.arrow-hidden
{
  display: none;
}
 
`;

export default GlobalStyle;
