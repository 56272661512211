import React, { useEffect } from "react";
import { Modal, Input, Button } from "antd";
import { Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { UpdateTokonomicsHook } from "hooks/adminHooks";
import InputField from "components/common/InputField/inputField";

const validationSchema = Yup.object().shape({
  token_name: Yup.string().required("Token name is required"),
  token_supply: Yup.number()
    .typeError("Token supply must be a number")
    .positive("Token supply must be a positive number")
    .required("Token supply is required"),
});

const ModalContent = styled.div`
  background: #fff;
  padding: 1rem;
`;

const UpdateTokonomicsModal = ({
  updateModalVisible,
  setupdateModalVisible,
  setIsDataUpdated,
  initialValuesForUpdate,
}) => {
  console.log("initialValuesForUpdate", initialValuesForUpdate);
  const hideModal = () => {
    setupdateModalVisible(false);
  };

  const { updateTokonomics, data, loading } = UpdateTokonomicsHook();

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      token_name: "",
      token_supply: "",
    },

    onSubmit: (values) => {
      console.log(values);

      // Perform update operation
      const token_name = values.token_name;
      const token_supply = values.token_supply;
      updateTokonomics(token_name, token_supply, initialValuesForUpdate?.id);

      // Reset the form after successful submission
      resetForm();
    },
  });

  console.log("vaaa", values);

  useEffect(() => {
    if (data) {
      setIsDataUpdated(true);
      hideModal();
    }
  }, [data, loading]);

  useEffect(() => {
    if (initialValuesForUpdate) {
      setFieldValue("token_name", initialValuesForUpdate.token_name);
      setFieldValue("token_supply", initialValuesForUpdate.token_supply);
    }
  }, [initialValuesForUpdate]);

  return (
    <div>
      <Modal
        title="Update Token Details"
        visible={updateModalVisible}
        onCancel={hideModal}
        footer={null}
      >
        <ModalContent>
          <form onSubmit={handleSubmit}>
            <div>
              <InputField
                inputLabel="Token Name"
                name="token_name"
                id="token_name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.token_name}
                error={
                  touched.token_name && errors.token_name
                    ? errors.token_name
                    : null
                }
                inputType="text"
              />
            </div>

            <div>
              <InputField
                inputLabel="token_supply"
                name="token_supply"
                id="token_supply"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.token_supply}
                error={
                  touched.token_supply && errors.token_supply
                    ? errors.token_supply
                    : null
                }
                inputType="text"
              />
            </div>

            <div className="my-2">
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </div>
          </form>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default UpdateTokonomicsModal;
