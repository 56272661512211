import React, { useEffect } from "react";
import { DataWrapper, MainWrapper, Title } from "./element";
import { Loader, MainContainer } from "components/common";
import { CryptoPriceHook } from "hooks/priceHooks";
import Marquee from "react-fast-marquee";
import { marketplaceData } from "components/data";
const data = [
  {
    name: "S&P 500",
    value: "-15.62 (-0.34%)",
  },
  {
    name: "S&P 500",
    value: "+267.06 (+0.76%)",
  },
  {
    name: "DOW 30",
    value: "-15.62 (-0.34%)",
  },
  {
    name: "S&P 500",
    value: "-15.62 (-0.34%)",
  },
  {
    name: "S&P 500",
    value: "+267.06 (+0.76%)",
  },
  {
    name: "DOW 30",
    value: "-15.62 (-0.34%)",
  },
  {
    name: "DOW 30",
    value: "-15.62 (-0.34%)",
  },
];

function LiveMarketPrices() {
  const { data: crptoData, loading } = CryptoPriceHook();
  console.log("cryy", crptoData);
  const isNegative = (value) => {
    return value?.startsWith("-");
  };

  console.log("envvv", process.env.REACT_APP_BACKEN_URI)

  // Create a CSS class for the color based on the value's sign
  const getValueColorClass = (value) => {
    return isNegative(value) ? "negative-value" : "positive-value";
  };


  useEffect(() => {
    const fetchCoins = async () => {
      const res = await fetch(`https://api.coincap.io/v2/assets`)
      const data = await res.json()
      console.log("price", data.data)

    }

    fetchCoins()
  }, [])
  return (
    <MainWrapper>
      {loading && <Loader content="Loading" />}

      <MainContainer>
        <Title>LIVE MARKET PRICES</Title>
        <DataWrapper>
          <Marquee>
            {crptoData?.slice(0, 100)?.map((item, index) => (
              <div key={index} className="dataaa">
                <h5>{item.name}</h5>
                <p>{Number(item?.priceUsd
                )?.toFixed(3)}</p>
              </div>
            ))}
          </Marquee>
        </DataWrapper>
      </MainContainer>
    </MainWrapper>
  );
}

export default LiveMarketPrices;
