import { MainCol, MainContainer, MainRow } from "components/common";
import React from "react";
import { BuyBtn, ImgWrapper, MainSection, Text, Title } from "./elements";
import { Image, Nav } from "react-bootstrap";
import { herogif } from "assets";
import { Link } from "react-router-dom";

const HeroComp = () => {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <MainSection>
      <MainContainer>
        <MainRow>
          <MainCol lg={6} className="left-div">
            <div className="hero-div">
              <Title>Problem and Solution</Title>
              <Text>
                This token is mainly for the crypto community, 90% of the supply
                is use for the liquidity and the other 10% is going to be used
                for services such as : web, applications , exchange listing and
                future developments. This project is going to be manage by an
                Anonymous business man for 24 month and then it will be transfer
                over to leader elected by the community ambassador.
              </Text>
              {/* <Nav.Link href="#buychepa"> */}
              <BuyBtn onClick={() => scrollToSection("buychepa")}>
                bUY $CHEPA
              </BuyBtn>
              {/* </Nav.Link> */}
            </div>
          </MainCol>
          <MainCol lg={6}>
            <ImgWrapper>
              <Image src={herogif} fluid />
            </ImgWrapper>
          </MainCol>
        </MainRow>
      </MainContainer>
    </MainSection>
  );
};

export default HeroComp;
