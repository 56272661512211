const environment = {
    BACKEND_BASE_URL: "http://16.170.228.185:8080",
    CONTRACT_ADDRESS: '0xad39a52BDCC9003e60e8b1451D77bD5570A588D4',
    MORALIS_APP_ID: 'Cong0Rh8UwDPV5HC4U0nCPxiwEz5PAxeFLP2f3i0',
    MORALIS_SERVER_URL: 'https://nbnob0befkpo.usemoralis.com:2053/server',
    CHEPA_TOKEN : "0xA3BEEf69ae5C28080a54C4720e48b38aa674B64f ",
CHEPA_FACTORY : "0x4f84309c3B5F2E6fE6B05ba5EbA7E1C44EDe8B3B",
CHEPA_DECIMALS:18,
DESIRED_NETWORK:"0x1",

};


export default environment;
