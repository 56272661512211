import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { MainModel } from "components/common";
import { mainModel } from "store/redux/slices/helperSlices/modelSlice";
import { switchNetwork } from "store/redux/slices/wallet3Connect/web3ConnectSlice";

import { networkObj } from "../data";
import { ContractUtility } from "utility/contract-utility";
import HeroComp from "./heroSection";
import LiveMarketPrices from "./liveMarketPrices";
import AboutComp from "./aboutSection";
import BuyComp from "./buySection";
import BuyChepaComp from "./buyChepaSection";
import TokennomicsComp from "./tokennomicsSection";
import FeedBackComp from "./feedbackSection";
import JoinComp from "./joinSection";
import FooterComp from "./footerSection";
import Topbar from "components/common/navbar";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
const HomeCom = () => {
  const dispatch = useAppDispatch();

  const [connectModel, setConnectModel] = useState(false);

  const { web3, account, chainId } = useAppSelector(
    (state) => state.web3Connect
  );

  // const {convertedPrice} = PriceConvertorHook({ amount: 1, id: "2" })

  const handleModelOpen = () => {
    setConnectModel(true);
    dispatch(mainModel(true));
  };

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 200) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      <div
        className={scrolled ? "arrow" : "arrow-hidden"}
        onClick={handleClick}
      >
        <MdOutlineKeyboardArrowUp color="#ffffff" fontSize={25} />
      </div>
      <Topbar />
      <HeroComp />
      <LiveMarketPrices />
      <AboutComp />
      <BuyComp />
      <BuyChepaComp />
      <TokennomicsComp />
      <FeedBackComp />
      <JoinComp />
      <FooterComp />
      {/* <MainModel connectModel={connectModel} />
      <button onClick={handleModelOpen}>Connect</button>

      {web3 ? (
          <select value={ContractUtility.getProtocol(chainId)} onChange={(e)=> switchNetwork(web3, e.target.value)} >
      {
        networkObj.map((network, i)=>{
         return <option key={i} value={network.value} >{network.name}</option>
        })
      }
    </select>
      ) : (
        ""
      )}
      <p>
        account: {account}
      </p> */}
    </div>
  );
};

export default HomeCom;
