import React, { useEffect, useState } from "react";
import {
  Balance,
  BtnWrapper,
  Btns,
  BuyBtn,
  ConnectBtn,
  InfoDiv,
  MainSection,
  MainWrapper,
  RightTitle,
  RightWrapper,
  SelectDiv,
  Title,
  TopTitle,
} from "./elements";
import {
  Loader,
  MainCol,
  MainContainer,
  MainRow,
  ToastMessage,
} from "components/common";
import { Form, Image } from "react-bootstrap";
import { Select, Tooltip } from "antd";
import { chepa, eth, info } from "assets";
import { BsArrowDown } from "react-icons/bs";
import { useAppSelector } from "store/store";
import { ERC20Service } from "web3Functions";
import environment from "environment";
import { CommonUtility } from "utility";
import { CHEPAFACTORYABI } from "web3Functions/chepafactory";
import transakSDK from "@transak/transak-sdk";

const settings = {
  apiKey: "cfdc5abd-2525-4d1a-9065-05e28cb03345", // Your API Key
  environment: "STAGING", // STAGING/PRODUCTION
  defaultCryptoCurrency: "ETH",
  themeColor: "000000", // App theme color
  hostURL: window.location.origin,
  widgetHeight: window?.innerWidth < 900 ? "500px" : "600px",
  widgetWidth: window?.innerWidth < 900 ?  "380px" : "500px",
};

const BuyChepaComp = () => {
  const [fromValue, setFromValue] = useState<string>("1");
  const [toValue, setToValue] = useState<string>("2");
  const [nativeBalance, setNativeBalance] = useState("");
  const [nativeWeiBalance, setNativeWeiBalance] = useState("");

  const [chepaBalance, setChepaBalance] = useState("");
  const [chepaWeiBalance, setChepaWeiBalance] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [amount, setAmount] = useState(Number);
  const [estimatedValue, setEstimatedValue] = useState("");

  console.log("fromValue", fromValue);
  console.log("toValue", toValue);

  const { web3, account, chainId } = useAppSelector(
    (state) => state.web3Connect
  );
  const handleChangeFrom = (value: string) => {
    setFromValue(value);
    if (value === "1") {
      setToValue("2"); // If "from" is Eth, then "to" is Chepa
      setAmount(0);
      setEstimatedValue("0");
    } else if (value === "2") {
      setToValue("1"); // If "from" is Chepa, then "to" is Eth
      setAmount(0);
      setEstimatedValue("0");
    }
  };

  const handleChangeTo = (value: string) => {
    setToValue(value);
    if (value === "1") {
      setFromValue("2"); // If "from" is Eth, then "to" is Chepa
      setAmount(0);
      setEstimatedValue("0");
    } else if (value === "2") {
      setFromValue("1"); // If "from" is Chepa, then "to" is Eth
      setAmount(0);
      setEstimatedValue("0");
    }
  };

  const { Option } = Select;

  const options = [
    { value: "1", label: "ETH", image: eth },
    { value: "2", label: "CHEPA", image: chepa },
  ];

  const renderOptionLabel = (option: any) => (
    <>
      {option.label}
      <img src={option.image} alt={option.label} style={{ marginLeft: 8 }} />
    </>
  );

  const getOptionLabel = (option: any) => {
    if (option.disabled) {
      return "";
    }
    return renderOptionLabel(option);
  };

  const getOptionProps = (option: any) => {
    const props: {
      style?: React.CSSProperties;
      onMouseEnter?: () => void;
      disabled?: boolean;
    } = {};
    if (option.disabled) {
      props.style = { cursor: "not-allowed" };
      props.onMouseEnter = () => {};
      props.disabled = true;
    }
    return props;
  };
  const getBalances = async () => {
    try {
      const nativeBalance = await web3.eth.getBalance(account);
      console.log("native", nativeBalance);
      setNativeWeiBalance(nativeBalance);
      setNativeBalance(CommonUtility.convertFromWei(nativeBalance, 18, false));
      const chepaBalance = await ERC20Service.balance(
        web3,
        account,
        environment.CHEPA_TOKEN
      );
      console.log("chepaBalance", chepaBalance);
      setChepaWeiBalance(chepaBalance);
      setChepaBalance(
        CommonUtility.convertFromWei(
          chepaBalance,
          environment.CHEPA_DECIMALS,
          false
        )
      );
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    if (account && web3) {
      getBalances();
    }
  }, [web3, account]);
  const handle25 = () => {
    if (fromValue == "1") {
      setAmount(+nativeBalance * 0.25);
    } else {
      setAmount(+chepaBalance * 0.25);
    }
  };

  const handle50 = () => {
    if (fromValue == "1") {
      setAmount(+nativeBalance * 0.5);
    } else {
      setAmount(+chepaBalance * 0.5);
    }
  };

  const handle75 = () => {
    if (fromValue == "1") {
      setAmount(+nativeBalance * 0.75);
    } else {
      setAmount(+chepaBalance * 0.75);
    }
  };
  const handleMax = () => {
    if (fromValue == "1") {
      setAmount(+nativeBalance);
    } else {
      setAmount(+chepaBalance);
    }
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  function openTransak() {
    const transak = new transakSDK(settings);

    transak.init();

    // To get all the events
    transak.on(transak.ALL_EVENTS, (data) => {
      console.log(data);
    });

    // This will trigger when the user closed the widget
    transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, (eventData) => {
      console.log(eventData);
      transak.close();
    });

    // This will trigger when the user marks payment is made.
    transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData) => {
      console.log(orderData);
      window.alert("Payment Success");
      transak.close();
    });
  }

  const buyTokens = async () => {
    try {
      setIsLoading(true);

      const chepaFactory = CommonUtility.contract(
        web3,
        CHEPAFACTORYABI,
        environment.CHEPA_FACTORY
      );
      let txn;
      if (fromValue == "1") {
        console.log("if amount");
        const amountInWei = CommonUtility.convertToWei(amount, 18);
        if (+nativeWeiBalance < +amountInWei) throw "Insufficient Balance";
        txn = await chepaFactory.methods
          .swapEthToTokens()
          .send({ from: account, value: amountInWei });
      } else {
        console.log("else amount");
        const amountInWei = CommonUtility.convertToWei(
          amount,
          environment.CHEPA_DECIMALS
        );
        if (+chepaWeiBalance < +amountInWei) throw "Insufficient Balance";
        const allowance = await ERC20Service.allowance(
          web3,
          account,
          environment.CHEPA_FACTORY,
          environment.CHEPA_TOKEN
        );
        if (+allowance < +amountInWei) {
          const approval = await ERC20Service.approve(
            web3,
            environment.CHEPA_FACTORY,
            amountInWei,
            account,
            environment.CHEPA_TOKEN
          );
          if (approval && approval.code == 4001)
            throw "User denied the transaction";
          if (!approval.status) throw "Transaction Failed";
        }
        txn = await chepaFactory.methods
          .swapTokensToEth(amountInWei)
          .send({ from: account });
      }
      if (txn && txn.code == 4001) throw "User denied the transaction";
      if (!txn.status) throw "Transaction Failed";
      if (txn.status) {
        await getBalances()
        setIsLoading(false);
        ToastMessage("Success!", "Transaction Successfull", "success");
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
      if (error?.message) {
        ToastMessage("Error", error?.message, "error");
      } else {
        ToastMessage("Error", error, "error");
      }
    }
  };
  const getEstimatedOutputAmount = async () => {
    try {
      setIsLoading(true);

      const chepaFactory = CommonUtility.contract(
        web3,
        CHEPAFACTORYABI,
        environment.CHEPA_FACTORY
      );

      if (fromValue == "1") {
        console.log("if amount");
        const amountInWei = CommonUtility.convertToWei(amount, 18);
        const estimatedValue = await chepaFactory.methods
          .getExpectedChepaAmount(amountInWei)
          .call();
        setEstimatedValue(
          CommonUtility.convertFromWei(
            estimatedValue,
            environment.CHEPA_DECIMALS,
            false
          )
        );
      } else {
        console.log("else amount");
        const amountInWei = CommonUtility.convertToWei(
          amount,
          environment.CHEPA_DECIMALS
        );
        const estimatedValue = await chepaFactory.methods
          .getExpectedETHAmount(amountInWei)
          .call();
        setEstimatedValue(
          CommonUtility.convertFromWei(estimatedValue, 18, false)
        );
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (web3) {
      getEstimatedOutputAmount();
    }
  }, [web3, amount]);
  return (
    <MainSection id="buychepa">
      {isLoading && <Loader content="Processing..." />}

      <MainContainer>
        <Title>buy chepa</Title>
        <MainRow style={{ paddingBottom: "2rem" }}>
          <MainCol lg={6}>
            <TopTitle>from</TopTitle>

            <MainWrapper>
              <SelectDiv>
                <Select
                  defaultValue="1"
                  style={{ width: 155 }}
                  onChange={handleChangeFrom}
                  optionLabelProp="label"
                  value={fromValue}
                >
                  {options.map((option) => (
                    <Option
                      key={option.value}
                      value={option.value}
                      label={getOptionLabel(option)}
                      {...getOptionProps(option)}
                    >
                      <div>
                        <img
                          src={option.image}
                          alt={option.label}
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "8px",
                          }}
                        />
                        {option.label}
                      </div>
                    </Option>
                  ))}
                </Select>
                <Form.Control
                  placeholder="0"
                  value={amount}
                  min={0}
                  onChange={handleAmountChange}
                />
              </SelectDiv>
              <div className="second-div">
                <Balance>
                  <h5> balance: </h5>
                  <p>
                    {fromValue == "1"
                      ? CommonUtility.decFixed(nativeBalance, 4)
                      : CommonUtility.decFixed(chepaBalance, 4)}{" "}
                  </p>
                </Balance>
                <BtnWrapper>
                  <Btns onClick={handle25}>25%</Btns>
                  <Btns onClick={handle50}>50%</Btns>
                  <Btns onClick={handle75}>75%</Btns>
                  <Btns onClick={handleMax}>MAX</Btns>
                </BtnWrapper>
              </div>
            </MainWrapper>
            <div className="icon">
              <BsArrowDown color="#A3AAB5" fontSize={20} cursor="pointer" />
            </div>
            <div className="mt-4">
              <TopTitle>to</TopTitle>

              <MainWrapper>
                <SelectDiv>
                  <Select
                    defaultValue="2"
                    style={{ width: 155 }}
                    onChange={handleChangeTo}
                    optionLabelProp="label"
                    value={toValue}
                  >
                    {options.map((option) => (
                      <Option
                        key={option.value}
                        value={option.value}
                        label={getOptionLabel(option)}
                        {...getOptionProps(option)}
                      >
                        <div>
                          <img
                            src={option.image}
                            alt={option.label}
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "8px",
                            }}
                          />
                          {option.label}
                        </div>
                      </Option>
                    ))}
                  </Select>
                  <Form.Control placeholder="0" value={estimatedValue} />
                </SelectDiv>
                <div className="second-div" style={{ paddingBottom: "1rem" }}>
                  <Balance>
                    <h5> balance: </h5>
                    <p>
                      {" "}
                      <p>
                        {fromValue == "1"
                          ? CommonUtility.decFixed(chepaBalance, 4)
                          : CommonUtility.decFixed(nativeBalance, 4)}{" "}
                      </p>{" "}
                    </p>
                  </Balance>
                </div>
              </MainWrapper>
            </div>
            <Tooltip title={account ? undefined : "Please Connect Wallet."}>
              <span>
                <ConnectBtn onClick={buyTokens} disabled={!account}>
                  Buy / Sell
                </ConnectBtn>
              </span>
            </Tooltip>
          </MainCol>
          <MainCol lg={1}></MainCol>
          <MainCol lg={5}>
            <RightWrapper>
              <RightTitle>steps</RightTitle>
              <div className="d-flex align-items-center gap-3 mt-4 number-div">
                <div className="number">
                  <h5>1</h5>
                </div>
                <div className="text">
                  <p>Click Connect wallet</p>
                </div>
              </div>

              <div className="d-flex align-items-center gap-3 mt-4 number-div">
                <div className="number">
                  <h5>2</h5>
                </div>
                <div className="text">
                  <p>Click Buy now</p>
                </div>
              </div>

              {/* <div className="btn-div">
                <RightTitle>no ETH? no problem</RightTitle>
                <h4>
                  Use transbank to buy ETH
                  <br /> directly with your credit card
                  <br /> and join
                </h4>

                <BuyBtn onClick={openTransak}>bUY ETH</BuyBtn>
              </div> */}

              <InfoDiv>
                <div>
                  <Image src={info} />
                </div>
                <div>
                  <h5>
                    Having issues connecting
                    <br /> your wallet? Please see
                    <br /> our <span>how to buy</span> section?
                  </h5>
                </div>
              </InfoDiv>
            </RightWrapper>
          </MainCol>
        </MainRow>
      </MainContainer>
    </MainSection>
  );
};

export default BuyChepaComp;
