export const Images = {
  web3: {
    metamask: require("./web3/metamask.png")?.default,
    wallet: require("./web3/wallet.png")?.default,
  },
  login: {
    bg: require("./images/bg-img.png")?.default,
  },
};

export { default as logo } from "./images/logo.png";
export { default as herogif } from "./images/gif.gif";
export { default as herobg } from "./images/herobg.png";
export { default as aboutimg } from "./images/about.png";
export { default as facebookimg } from "./images/facebook.png";
export { default as twitterimg } from "./images/twitter2.svg";
export { default as telegramimg } from "./images/telegram.png";
export { default as youtubeimg } from "./images/insta.png";
export { default as info } from "./images/info.png";
export { default as eth } from "./images/eth.png";
export { default as chepa } from "./images/chepa.png";
export { default as hinsta } from "./images/hinsta.png";
export { default as htwitter } from "./images/htwitter2.png";

export { default as htelegram } from "./images/htelegram.png";
export { default as insta } from "./images/insta.png";
export { default as whitepaper } from "./images/whitepaper.pdf";
