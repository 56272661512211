import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AdminService } from "services/adminServices";
import { useAppSelector } from "store/store";
import { AuthType, BrowserUtility, StorageConstant } from "utility";
import { CommonHook } from "./commonHook";

export const CreateTokonomicsHook = () => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();

  const createTokonomics = async (credentials: {
    token_name: string;
    token_supply: string;
  }) => {
    try {
      setLoading(true);
      setData(null);
      const result = await AdminService.createTokonomicks(credentials);
      setData(result.data);
    } catch (error) {
      setError(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return {
    createTokonomics,
    data,
    loading,
    error,
  };
};

export const UpdateTokonomicsHook = () => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();

  const updateTokonomics = async (token_name, token_supply, id) => {
    try {
      setLoading(true);
      const result = await AdminService.updateTokonomics({
        token_name,
        token_supply,
        id,
      });
      setData(result.data);
    } catch (error) {
      setError(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return {
    updateTokonomics,
    data,
    loading,
    error,
  };
};

export const DeleteTokonomicsHook = () => {
  const { data, setData, setError, loading, setLoading, error } = CommonHook();

  const deleteTokonomics = async (id) => {
    try {
      setLoading(true);
      setData(null);
      const result = await AdminService.deleteTokonmics(id);
      setData(result.data);
    } catch (error) {
      setError(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return {
    deleteTokonomics,
    data,
    loading,
    error,
  };
};

export const GetTokonmicsHook = () => {
  const { loading, data, setLoading, setData } = CommonHook();
  const navigate = useNavigate();

  const getTokonmics = async () => {
    try {
      setLoading(true);
      setData(null);
      const res = await AdminService.getTokonomics();
      setData(res.data);
    } catch (error) {
      console.log("eee", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTokonmics();
  }, []);

  return {
    loading,
    data,
    getTokonmics,
  };
};

export const LeaveFeedbackHook = () => {
  const { loading, data, setLoading, setData } = CommonHook();

  const leaveFeedback = async (data) => {
    try {
      console.log("leaveee",data)
      setLoading(true);
      setData(null);
      const res = await AdminService.leaveFeedback(data);
        console.log("resss",res);
      setData(res);
    } catch (error) {
      console.log("eee", error);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    data,
    leaveFeedback,
  };
};
