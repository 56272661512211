import styled from "styled-components";

export const MainSection = styled.section`
  overflow: hidden;

  .container {
    padding-bottom: 6rem;
    margin-top: 6rem;
  }
`;

export const ImgWrapper = styled.div`
  text-align: center;

  @media (max-width: 767px) {
    margin-top: -5rem;
  }
`;

export const Title = styled.h5`
  color: #fff;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.5px; /* 42.969% */

  @media (max-width: 990px) {
    text-align: center;
    margin-top: 3rem;
    font-size: 40px;
  }
`;

export const Text = styled.p`
  color: #fff;
  font-family: Readex Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  text-align: justify;
  line-height: 27.5px; /* 137.5% */
  /* width: 28rem; */
  margin-top: 2rem;

  @media (max-width: 990px) {
    /* text-align: center; */
    padding: 0rem 0.5rem;
    margin-top: 2rem;
    width: 100%;
    font-size: 16px;
  }
`;
export const BuyBtn = styled.button`
  color: #fff;
  width: 129px;
  height: 45px;
  border-radius: 10px;
  border: 2px solid #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.5px; /* 137.5% */
  text-transform: capitalize;
  background-color: transparent;
  margin-top: 1.5rem;

  &:hover {
    border-radius: 0.625rem;
    background: #4bae32;
    border: none;
  }

  @media (max-width: 990px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
  }
`;
