import { BaseService } from "./baseService";
import { APIPath } from "../utility/constant/apiPath";

class Admin {
  createTokonomicks = (data) => {
    return BaseService.post("create-tokonomics", data);
  };
  
  getTokonomics = () => {
    return BaseService.get("get-tokonomics");
  };
  updateTokonomics = (data) => {
     
    return BaseService.put(`update-tokonomics/${data?.id}`, data);
  };
  deleteTokonmics = (id) => {
    return BaseService.delete(`delete-tokonomics/${id}`);
  };

  leaveFeedback = (data) => {
    return BaseService.post("leave-feedback", data);
  };
}

const AdminService = new Admin();
Object.freeze(AdminService);
export { AdminService };
