import styled from "styled-components";

export const MainModel = styled.div``;

export const ModelsData = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  width: 400px;
  height: 300px;

  background: ${(p) =>
    p.theme.model.connectModelbg && p.theme.model.connectModelbg};

  border: "2px solid #000";
  text-align: center;
  border-radius: 30px 30px 30px 30px;
  @media (max-width: 420px) {
    width: 370px;
    height: 300px;
  }

  @media (max-width: 380px) {
    width: 330px;
    height: 300px;
  }
`;

export const ConnectButton = styled.button`
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  border-radius: 30px;
  width: 360px;
  height: 110px;

  background: ${(p) =>
    p.theme.model.connectModelbtnBgColor &&
    p.theme.model.connectModelbtnBgColor};
  color: ${(p) =>
    p.theme.model.connectModelbtnTextColor &&
    p.theme.model.connectModelbtnTextColor};
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: 0.3s ease-in-out;
  cursor: pointer;

  border: none;
  font-weight: 700;
  font-size: 19px;
  @media (max-width: 415px) {
    width: 300px;
  }
`;

export const ConnectIcon = styled.img`
  width: 60px;
  margin-right: 1rem;
`;

export const ConnectModalWrapper = styled.div`
  overflow: hidden;
  min-height: 345px;
  border-radius: 50px;
  border: 3px solid #4bae32;
  background: #0d1522;

  padding: 10px 20px;

  @media (max-width: 575.98px) {
    padding-bottom: 2rem;
  }

  .closeIcon {
    display: block;
    margin-left: auto;
    color: #fff;
    width: 23px;
    height: 23px;
    cursor: pointer;
    transition: 0.3s ease-in-out all;

    &:hover {
      scale: 1.2;
      transition: 0.3s ease-in-out all;
    }
  }
`;

export const ConnectWalletText = styled.h2`
  text-align: center;
  color: #fff;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 3rem;

  @media (max-width: 575.98px) {
    margin-top: 1rem;
    font-size: 24px;
  }
`;
export const ButtonHolder = styled.div`
  margin-top: 4rem;

  @media (max-width: 575.98px) {
    margin-top: 2rem;
  }
`;

export const LeftDiv = styled.div`
  width: 136.04px;
  height: 100.16px;
  border: 0.5px solid #8e92a3;
  /* filter: drop-shadow(3px 3px 47px #ffd452); */
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;

  &:hover {
    border-radius: 10px;
    border: 2px solid #4bae32;
    background: #0d1522;

    /* ds */
    box-shadow: -4px 4px 40px 0px rgba(75, 174, 50, 0.72);
  }

  .icon {
    height: 37px;
    width: 41.47px;
  }
`;

export const RightDiv = styled.div`
  width: 136.04px;
  height: 100.16px;
  border: 0.5px solid #8e92a3;
  /* filter: drop-shadow(3px 3px 47px #ffd452); */
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;

  @media (max-width: 575.98px) {
    margin-top: 1rem;
  }

  .icon {
    height: 37px;
    width: 51.47px;
  }

  &:hover {
    border-radius: 10px;
    border: 2px solid #4bae32;
    background: #0d1522;

    /* ds */
    box-shadow: -4px 4px 40px 0px rgba(75, 174, 50, 0.72);
  }
`;

export const BtnText = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 12.8679px;
  line-height: 35px;
  color: #ffffff;
`;
