import React from "react";
import { MainSection, MainWrapper, Title } from "./elements";
import { MainCol, MainContainer, MainRow } from "components/common";
import { GetTokonmicsHook } from "hooks/adminHooks";
import { tokonomicsDummyData } from "components/data";

const TokennomicsComp = () => {
  const {data:ToknomicsData,loading}=GetTokonmicsHook();
  
  console.log("tok", ToknomicsData)
  const data = [
    {
      name: "Token Supply",
      value: "500,000,000,000,000",
    },
    {
      name: "Token Supply",
      value: "500,000,000,000,000",
    },
    {
      name: "Token Supply",
      value: "500,000,000,000,000",
    },
    {
      name: "Token Supply",
      value: "500,000,000,000,000",
    },
    {
      name: "Token Supply",
      value: "500,000,000,000,000",
    },
    {
      name: "Token Supply",
      value: "500,000,000,000,000",
    },
    {
      name: "Token Supply",
      value: "500,000,000,000,000",
    },
    {
      name: "Token Supply",
      value: "500,000,000,000,000",
    },
  ];

  return (
    <MainSection id="tokennomics">
      <MainContainer>
        <Title>TOKENOMICS</Title>

        {tokonomicsDummyData?.slice(0, 1).map((item) => (
          <div className="first-div">
            <MainWrapper>
            <h5>{item?.token_name}</h5>
                <p>{item?.token_supply}</p>
            </MainWrapper>
          </div>
        ))}

        <MainRow>
          {tokonomicsDummyData?.slice(1, 7).map((item) => (
            <MainCol lg={4} className="first-div">
              <MainWrapper>
                <h5>{item?.token_name}</h5>
                <p>{item?.token_supply}</p>
              </MainWrapper>
            </MainCol>
          ))}
        </MainRow>
      </MainContainer>
    </MainSection>
  );
};

export default TokennomicsComp;
