import styled, { css } from "styled-components";

import { Form } from "react-bootstrap";

 
export const NftForms = styled(Form)``;

interface Props {}

export const FormControl = styled(Form.Control)<Props>``;
