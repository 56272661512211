import styled from "styled-components";

export const NavbarWrapper = styled.div`
  .navbar {
    background-color: transparent !important;
    border-bottom: 2px solid #fff;
  }

  .navbar-light .navbar-nav .nav-link {
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.5px; /* 137.5% */
    text-transform: capitalize;
    margin-right: 1rem;

    @media (max-width: 767px) {
      text-align: center;
    }
  }

  .navbar-toggler-icon {
    background-color: #4bae32;
  }
`;

export const ConnectBtn = styled.button`
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.5px; /* 137.5% */
  text-transform: capitalize;
  width: 147px;
  /* padding: 1rem 0rem; */
  height: 37px;
  border-radius: 10px;
  border: 2px solid #fff;
  background-color: transparent;
  @media (max-width: 767px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
`;
