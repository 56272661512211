import React, { useState } from "react";
import { BuyBtn, ImgWrapper, MainSection, Text, Title } from "./elements";
import { MainCol, MainContainer, MainRow } from "components/common";
import { Image } from "react-bootstrap";
import { aboutimg } from "assets";
import { Modal } from "antd";
import PaymentModal from "components/common/modal/paymentModal";

const AboutComp = () => {
  const [modal1Visible, setModal1Visible] = useState(false);

  const closeModel = () => {
    setModal1Visible(false);
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <MainSection id="about">
      <Modal
        destroyOnClose={true}
        style={{ top: 20 }}
        visible={modal1Visible}
        centered
        onCancel={closeModel}
      >
        <PaymentModal onCancel={closeModel} />
      </Modal>
      <MainContainer>
        <MainRow>
          <MainCol lg={6}>
            <ImgWrapper>
              <Image src={aboutimg} fluid />
            </ImgWrapper>
          </MainCol>
          <MainCol lg={6}>
            <Title>ABOUT</Title>
            <Text>
              The growth of cryptocurrencies has increased over the years,
              according to statistics the average ownership rate of
              cryptocurrencies is 3.9% – that means that more than 300 million
              people use cryptocurrencies.
              <br /> <br /> The great adaptation of the last few years creates a
              market with great potential, so many projects are entering the
              space. Currently, there are thousands of projects with their
              tokens on the market; however, not all of them are successful, a
              success that is related to the economics of the token, utility,
              and the bridge to real/everyday life that the project offers.
              <br />
              <br />
              Chepa arises for the benefit of the entire community with a
              utility token and a dynamic economy for the benefit of all
              users/investors. It will be a project created under the Ethereum
              Network, a project that will have its own ERC-20 $Chepa token.
            </Text>
            <BuyBtn onClick={() => scrollToSection("buychepa")}>bUY NOW</BuyBtn>
          </MainCol>
        </MainRow>
      </MainContainer>
    </MainSection>
  );
};

export default AboutComp;
