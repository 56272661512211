import { useEffect, useState } from "react";
import { CMCService } from "services/currencyServices";

export const CryptoPriceHook = () => {
  const [cryptoData, setCryptoData] = useState(null);
  const [loading, setLoading] = useState(false);

  const getCrypto: any = async () => {
    try {
      setLoading(true);
     
      const res = await fetch(`https://api.coincap.io/v2/assets`)
      const data = await res.json()
      console.log("price", data.data)

      if (data) setCryptoData(data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      return "error occured";
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getCrypto();
  }, []);

  return {
    data: cryptoData,
    loading
  };
};
